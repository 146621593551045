import 'intersection-observer'; // polyfill
import isbot from 'isbot';
import React, { createContext, CSSProperties, FC, ReactNode } from 'react';
import InView, { IntersectionOptions } from 'react-intersection-observer';
import { isSSG } from '~/config';
import { useIsTabbing } from '~/hooks';

export const IsVisibleContext = createContext<{ isVisible: boolean }>({
  isVisible: true,
});

type VisibleInViewProps = {
  /** Pass true to do nothing. Automated for bots/crawlers */
  disabled?: boolean;
  /**
   * Bypasses disabled-during-tabbing default behavior (reenabled via mouse move).
   *
   * WARNING: use sparingly! Harms a11y because hidden elements cannot be tabbed to.
   */
  ignoreTabKey?: boolean;
  /** Added to wrapper div */
  style?: CSSProperties;
  /** Added to wrapper div */
  id?: string;
  /** Pass true to log the id and visibility (id is required) */
  logVisibility?: boolean;
  /** In-view options */
  options?: IntersectionOptions;
  children?: ReactNode;
};

/**
 * Auto-hide content when out of view, to help with perf.
 *
 * The state `isVisible` is also provided to children via `IsVisibleContext`.
 *
 * To avoid harming SEO, children are always rendered server-side,
 * then only hidden client-side if a crawler agent is not detected.
 */
export const VisibleInView: FC<VisibleInViewProps> = ({
  disabled,
  ignoreTabKey,
  style = {},
  id,
  logVisibility = false, // set to true temporarily to log all
  options = {} as IntersectionOptions,
  children,
}) => {
  const isTabbing = useIsTabbing(ignoreTabKey);
  const isDisabled =
    disabled || isSSG || isTabbing || isbot(navigator.userAgent);
  const shouldLog = !isSSG && logVisibility && id;

  return (
    <InView {...options} disabled={isDisabled}>
      {({ inView, ref }) => {
        const show = isDisabled || inView;
        if (shouldLog) {
          console.info(`'${id}' visible: ${show}`);
        }

        return (
          <IsVisibleContext.Provider value={{ isVisible: show }}>
            <div
              id={id}
              ref={ref}
              style={{
                ...style,
                visibility: show ? 'visible' : 'hidden',
              }}
            >
              {children}
            </div>
          </IsVisibleContext.Provider>
        );
      }}
    </InView>
  );
};

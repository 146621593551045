import React, { FC } from 'react';

enum SpaceSize {
  xs = 4,
  sm = 10,
  md = 24,
  lg = 30,
  xl = 44,
  xxl = 50,
  xxxl = 64,
}

type SpaceProps = {
  // Pass nothing for md: <Space />

  // Or a flag: <Space sm />
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
  xxxl?: boolean;

  // or pass px={number} for a specific height
  size?: number | string;

  // Invert the gap to pull next element up (vertical/number only)
  negative?: boolean;

  // Default is vertical gap
  horizontal?: boolean;
};

export const Space: FC<SpaceProps> = (props) => {
  const size =
    (props.size &&
      typeof props.size === 'number' &&
      props.size * (props.negative && props.size > 0 ? -1 : 1)) ||
    (props.size && typeof props.size === 'string' && props.size) ||
    (props.xs && SpaceSize.xs) ||
    (props.xs && SpaceSize.xs) ||
    (props.sm && SpaceSize.sm) ||
    (props.lg && SpaceSize.lg) ||
    (props.xl && SpaceSize.xl) ||
    (props.xxl && SpaceSize.xxl) ||
    SpaceSize.md;

  const att =
    (props.negative && 'marginTop') ||
    (props.horizontal && 'width') ||
    'height';

  return <div style={{ [att]: size }} />;
};

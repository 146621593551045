import gsap, { ScrollToPlugin } from 'gsap/all';
import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { Plx } from '~/components/Plx';
import { isSSG } from '~/config';
import { desktop, tablet } from '~/config/mq';
import { PlxBreakProps } from '~/util/plx';
import { BeatingButton } from './BeatingButton';

gsap.registerPlugin(ScrollToPlugin);

const pageHeadingPlxData = [
  {
    start: 0,
    end: 300,
    properties: [
      // {
      //   property: 'opacity',
      //   startValue: 1,
      //   endValue: 0,
      // },
      {
        property: 'translateY',
        startValue: 0,
        endValue: -150,
      },
    ],
  },
];

// -- styles --

const PageHeadingPlx = styled(Plx)`
  width: 100%;
  height: 40vh;
  position: relative;
  z-index: 2;
`;

const Container = styled.div`
  width: 100%;
  height: 40vh;
  position: relative;
  z-index: 2;
  display: grid;
  align-content: center;
  text-align: center;
`;

const Heading = styled.h1`
  transition: all 0.2s ease-in-out;

  color: black;
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.lg};
  line-height: 1;
  margin: 0 10px;
  text-align: center;
  ${tablet`
    font-size: 64px;
  `}
  ${desktop`
    font-size: 90px;
  `}
`;

const SubHeading = styled.h2`
  color: ${({ theme }) => theme.color.green};
  font-family: ${({ theme }) => theme.font.heading};
  font-size: 24px;
  margin: 0.5em;
  text-align: center;
  line-height: 1.25;
  ${tablet`
    font-size: 30px;
    line-height: 2.25;
    margin: 0;
  `}
  ${desktop`
    font-size: 36px;
    line-height: 2.25;
    margin: 0;
  `}
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  position: relative;
  z-index: 2;
`;

export const PageHeading: FC<PlxBreakProps> = ({ bp }) => {
  const tween = useRef<gsap.core.Tween>(null);

  const handleClick = () => {
    if (!isSSG) {
      document.documentElement.style.scrollBehavior = 'auto';
      if (tween.current) {
        tween.current.kill();
      }
      tween.current = gsap.to(window, {
        autoKill: true,
        scrollTo: bp === 'm' ? 350 : 600,
        delay: 0,
        duration: 1.5,
        ease: 'strong.out',
        onComplete: () =>
          (document.documentElement.style.scrollBehavior = 'smooth'),
      });
    }
  };
  return (
    // <PageHeadingPlx parallaxData={responsivePlx(bp, pageHeadingPlxData)}>
    <Container>
      <Heading>
        Have a heart.
        <br />
        Go green.
      </Heading>
      <SubHeading>Every day is Earth Day, y'all.</SubHeading>
      <ButtonWrapper>
        <BeatingButton
          fullOfHeart
          wearOnSleeve
          label="get started"
          onClick={handleClick}
        />
      </ButtonWrapper>
    </Container>
    // </PageHeadingPlx>
  );
};

import React, { FC } from 'react';
import styled from 'styled-components';
import { data } from '~/config/data-types';
import SwipeSVG from '../../images/swipe.svg';
import { Heading } from '../common/styled-components/Typography';
import { WayToHelpMobile } from './WayToHelpMobile';

const trayItemGap = 30;

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const TrayContainer = styled.div`
  width: 100vw;
  padding: 0 ${trayItemGap}px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Tray = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ count }) => count},
    calc(100vw - ${trayItemGap * 2}px)
  );
  justify-items: center;
  align-items: center;
  margin: 20px 0;
`;

const ImgContainer = styled.div`
  scroll-snap-align: center;
  padding: 0 0.5em 0 4em; // uneven to offset for the swipe indicator and tile peek on right
`;

const Img = styled.img`
  width: 240px;
  height: 320px;
  align-self: center;
`;

const ImgHeading = styled(Heading)`
  transition: all 0.2s ease-in-out;

  color: ${({ theme }) => theme.color.green};
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.h1Mobile};
  line-height: 1;
  margin: 0;
`;

// TODO these could be imported from the desktop component
const Text = styled.div`
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.25;
`;

const Swipe = styled(SwipeSVG)`
  width: 5em;
  height: auto;
  margin-top: 110px;
`;

const SideBySide = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
`;

type HowToHelpMobileProps = {
  //
};

export const HowToHelpMobile: FC<HowToHelpMobileProps> = (props) => {
  const ways = data.waysToHelp;

  return (
    <Container>
      <Heading>So, how can you help?</Heading>
      {ways.map((way) => (
        <TrayContainer key={way.heading}>
          <Tray count={ways.length + 1}>
            {[
              <SideBySide key={`${way.heading} cover`}>
                <ImgContainer>
                  <Img src={way.image} />
                  <ImgHeading>{way.heading}</ImgHeading>
                  <Text>{way.text}</Text>
                </ImgContainer>
                <Swipe />
              </SideBySide>,

              ...way.links.map((item) => (
                <WayToHelpMobile key={item.url} {...item} />
              )),
            ]}
          </Tray>
        </TrayContainer>
      ))}
    </Container>
  );
};

import React, { FC, useState } from 'react';
import { PlayState, Tween } from 'react-gsap';
import Lottie from 'react-lottie-player';
import styled from 'styled-components';
import { Plx } from '~/components/Plx';
import { desktop, tablet } from '~/config/mq';
import blueBirdLottie from '~/data/blue-bird.json';
import { PlxBreakProps, responsivePlx } from '~/util/plx';
import heartSrc from '../images/about-heart@2x-with-branches.png';
import { Heading } from './common/styled-components/Typography';

const drift = -1600;

const bounce_d = 0;
const bounce_m = 0;
const plxData = [
  // slide left w/bounce
  {
    start: 'self',
    duration: '10vh',
    startOffset: { m: '20vh', d: '20vh' },
    easing: [0.19, 1.19, 0.74, 0.98],
    properties: [
      {
        property: 'translateX',
        startValue: 0,
        endValue: { m: drift - bounce_m, d: drift - bounce_d },
      },
      /* {
        property: 'opacity',
        startValue: 0,
        endValue: 1,
      }, */
    ],
  },
];

const InitiativePlx = styled(Plx)`
  z-index: 1;
`;

const InitiativeHeading = styled(Heading)<{ isMobileLandscape: boolean }>`
  margin-bottom: 1em;
  text-align: left;
  margin-right: 50px;

  ${(props) =>
    props.isMobileLandscape
      ? tablet`
          text-align: left;
          margin-bottom:15px;
          `
      : tablet`
          text-align: left;
          margin-bottom: 2em;
          `};

  ${desktop`
    margin-bottom: 1em !important; // important is gatsby style order fix
    line-height: 140px !important; // important is gatsby style order fix
  `}
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 1em;
  right: 0;
  ${tablet`
    right: ${drift}px;
  `}
  @media (min-width:990px) and (max-height: 650px) {
    margin-top: 6em; // short laptops had bad crashing
  }
`;

const ContentContainer = styled.div`
  position: relative;
  margin-left: 20px;
  ${tablet`
    text-align: left;
    margin-left: 15%;
  `};
`;

const TextArea = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
  strong {
    color: ${({ theme }) => theme.color.green};
  }
  font-size: ${({ theme }) => theme.font.size.md};
  line-height: 1.75;
  min-height: 270px;
  margin-right: -3em; // stick out a bit extra for slide-in bounce
  padding: 1.5em;
  padding-right: 4.5em; // resets the extra margin
  border: solid black;
  border-width: 16px 0 16px 15px;
  border-top-left-radius: 42px;
  border-bottom-left-radius: 42px;
  ${tablet`
    margin-top: 6em;
  `}
  ${desktop`
    // uses desktop to miss mobile landscape
    margin-left: 100px;
    padding: 4em 3.25em;
  `};
`;

const Bird = styled.div<{ isMobileLandscape: boolean }>`
  z-index: 1;
  position: absolute;
  // need to use the lottie's real size to keep position stable
  width: 2000px;
  height: 2000px;
  transform: scale(0.5);
  top: -692px;
  right: -875px;
  ${(props) =>
    props.isMobileLandscape
      ? tablet`
      transform: scale(0.5);
      top: -692px;
      right: -793px;
    `
      : tablet`
      transform: scale(0.7);
      top: -500px;
      right: -843px;
    `}
  ${desktop`
    transform: scale(0.8);
    top: -354px;
    right: -620px;
  `}

  pointer-events: none;
`;
const HeartImg = styled.img`
  z-index: -1;
  position: absolute;
  top: 350px;
  left: -272px;
  width: 798px;
  height: 409px;

  ${tablet`
    transform: scale(0.7);
    top: -190px;
    left: -285px;
  `}
  ${desktop`
    transform: scale(0.9);
    top: -190px;
    left: -250px;
  `}
`;

export const AboutTheInitiative: FC<
  PlxBreakProps & { isMobile: boolean; isLandscape: boolean }
> = ({ bp, isMobile, isLandscape }) => {
  const [isSprouted, setIsSprouted] = useState(false);
  const handleShow = () => setIsSprouted(true);
  const handleHide = () => setIsSprouted(false);
  const content = (
    <Container>
      <ContentContainer>
        <InitiativeHeading isMobileLandscape={isMobile && isLandscape}>
          About the initiative
        </InitiativeHeading>
        <TextArea>
          {!isMobile && (
            <>
              <Tween
                playState={isSprouted ? PlayState.play : PlayState.pause}
                from={{ y: 100, scale: 0.5 }}
                ease="elastic.out(0.2, 0.1)"
                delay={0.1}
              >
                <HeartImg src={heartSrc} />
              </Tween>
            </>
          )}
          At y'all, we encourage each person to treat others with kindness. This
          doesn't stop with treating other people kindly, but also treating the
          environment around us with respect. During the last year, we have been
          challenged in ways we never imagined. We had to get creative with how
          we sustained an eco-friendly environment around us.{' '}
          <strong>And we wanted to share some of that with y’all!</strong>
        </TextArea>
        <Bird isMobileLandscape={isMobile && isLandscape}>
          <Lottie play={isSprouted} animationData={blueBirdLottie} />
        </Bird>
      </ContentContainer>
    </Container>
  );

  return isMobile ? (
    content
  ) : (
    <InitiativePlx
      parallaxData={responsivePlx(bp, plxData)}
      onPlxStart={handleHide}
      onPlxEnd={handleShow}
    >
      {content}
    </InitiativePlx>
  );
};

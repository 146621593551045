import { navigate } from 'gatsby';
import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import { isSSG } from '~/config';

export const PageExitStarted = 'exitstarted';

export type PageExitOptions = {
  seconds?: number;
  hrefTarget?: string;
};

export type PageExitClickOptions = PageExitOptions & {
  onClick?: MouseEventHandler<HTMLElement>;
};

export const DefaultPageExitOptions: PageExitOptions = {
  // Delay before link is fired, spans all transitions
  seconds: 0.75,
  hrefTarget: '_blank',
};

/**
 * Primary delayed-link helper that triggers page transitions.
 *
 * Be sure to keep your `to` or `href` while adding this, so Gatsby
 * SEO and site-mapping work correctly!
 *
 * Examples:
 *
 * <Link to="/contact" onClick={getPageExitClick('/contact')} />
 *
 * <a href="https://itsyall.com" onClick={
 *   getPageExitClick('https://itsyall.com', { seconds: 0.5 })
 * }/>
 *
 * <Fade withPageExit>
 *   <div>Goodbye, cruel world!</div>
 * </Fade>
 *
 *
 * Anchor tags get noreferrer/noopener functionality when fired.
 *
 * @param toOrHref Gatsby Link `to` prop or external url
 * @param options.seconds delay before link is executed
 * @param options.hrefTarget defaults to '_blank' if not passed
 * @param options.onClick custom click event fired right away
 * @returns Click handler function
 */
export const getPageExitClick = (
  toOrHref: string,
  options: PageExitClickOptions = DefaultPageExitOptions,
): MouseEventHandler => (e: MouseEvent<HTMLElement>) => {
  options?.onClick && options?.onClick(e);
  // TODO Needs to reject if path matches current location
  // tech debt ticket: https://itsyall.monday.com/boards/988860595/pulses/1194605239
  if (!isSSG && !e.defaultPrevented) {
    // stop link from firing
    e.preventDefault();
    e.stopPropagation();
    runPageExitSequence(toOrHref, options);
  }
};

/**
 * Executes a link after a delay.
 *
 * @param toOrHref Gatsby Link `to` prop or external url
 * @param options.seconds delay before link is executed
 * @param options.hrefTarget defaults to '_blank' if not passed
 */
export const runPageExitSequence = (
  toOrHref: string,
  options = DefaultPageExitOptions,
) => {
  if (isSSG) {
    return;
  }

  const seconds = options?.seconds ?? DefaultPageExitOptions.seconds!;
  const hrefTarget = options?.hrefTarget ?? DefaultPageExitOptions.hrefTarget;

  clearTimeout(_pageExitTimeout);
  _pageExitTimeout = setTimeout(() => {
    const isHref = /^https?:\/\//.test(toOrHref);
    if (isHref) {
      window.open(toOrHref, hrefTarget, 'noreferrer');
    } else {
      navigate(toOrHref);
    }
  }, seconds * 1000);

  // notify Fades
  window.dispatchEvent(new Event(PageExitStarted));
};

// uses a central timeout so only one link can execute
let _pageExitTimeout: NodeJS.Timeout;

/**
 * One-way state change indicating page exit in progress.
 * This is mostly for internal use in components like Fade
 * but can also be used for cases where you want to run your
 * own CSS page-exit animations.
 *
 * const { isExitingPage } = usePageExit();
 *
 * @param enabled Pass false to skip using this hook
 * @returns Object with `isExitingPage` binding
 */
export const usePageExit = (enabled = true) => {
  const [isExitingPage, setIsExitingPage] = useState(false);

  /*
   * Events are more straightforward than context for this, since
   * listeners only want a one-time trigger, not a state that resets.
   */
  useEffect(() => {
    const handleExitStarted = () => {
      setIsExitingPage(true);
      window.removeEventListener(PageExitStarted, handleExitStarted);
    };

    if (!isSSG && enabled) {
      window.addEventListener(PageExitStarted, handleExitStarted);
    }
    return () => {
      if (!isSSG) {
        window.removeEventListener(PageExitStarted, handleExitStarted);
      }
    };
  }, [enabled]);

  return { isExitingPage };
};

import React, { FC } from 'react';
import styled from 'styled-components';
import { Plx } from '~/components/Plx';
import { data } from '~/config/data-types';
import { Heading } from '../common/styled-components/Typography';

const cardPlxData = (index: number, total: number) => {
  const rotation = (index - (total - 1) / 2) * 17;
  return [
    {
      start: '#help-marker',
      startOffset: '50vh',
      duration: '40vh',
      easing: 'easeInOutQuint',
      properties: [
        {
          property: 'rotate',
          startValue: 0,
          endValue: rotation,
        },
      ],
    },
  ];
};

const imgPlxData = (index: number, total: number) => {
  const startScale = (1 + (index > 1 ? total - index : index) * 0.1) * 0.8;
  return [
    {
      start: '#help-marker',
      startOffset: '20vh',
      duration: '20vh',
      easing: 'easeOutQuint',
      properties: [
        {
          property: 'scale',
          startValue: startScale,
          endValue: 1,
        },
      ],
    },
  ];
};

const labelPlxData = [
  {
    start: '#help-marker',
    startOffset: '50vh',
    duration: '20vh',
    easing: 'easeInQuint',
    properties: [
      {
        property: 'opacity',
        startValue: 0,
        endValue: 1,
      },
    ],
  },
];

// -- styles --

const Fan = styled.div<{ totalCards: number }>`
  pointer-events: none;
  position: relative;
  display: flex;
  width: 1400px;
  margin-left: calc((1400px - 100vw) / 2 * -1);
  height: 420px;
  border-radius: 50% 50% 0 0;
  justify-content: center;
`;

const Card = styled.div<{
  isSelected: boolean;
  isInteractive: boolean;
  index: number;
  totalCards: number;
}>`
  pointer-events: all; // needed to override the Layout rule due to inheritance
  cursor: ${(props) => (props.isInteractive ? 'pointer' : 'auto')};
  user-select: none; // prevent blue selection from sticking on image
  opacity: ${(props) => (!props.isInteractive || props.isSelected ? 1 : 0.5)};
  transform: scale(
    ${(props) => (!props.isInteractive || props.isSelected ? 1 : 0.95)}
  );
  transform-origin: center 80%;
  transition: all 0.3s ease-in-out;
  &::after {
    ${(props) =>
      props.isSelected &&
      ` content: '';
        position: absolute;
        margin: 1em auto;
        border-left: 3px dashed ${props.theme.color.green};
        animation: draw-line 0.5s;
        animation-fill-mode: forwards;
        @keyframes draw-line {
          0% {
            height: 0;
          }
          100% {
            height: 9em;
          }
        }
      `}
  }
`;

const Img = styled.img`
  width: 270px;
  height: 364px;
  max-width: 100%;
  margin-top: 1em;
  align-self: center;
`;

const ImgHeading = styled(Heading)<{
  isSelected: boolean;
  isInteractive: boolean;
}>`
  transition: all 0.2s ease-in-out;
  color: ${(props) =>
    !props.isInteractive || props.isSelected
      ? ({ theme }) => theme.color.green
      : ({ theme }) => theme.color.blue};
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.h1Mobile};
  line-height: 1;
  margin: 10px;
`;

type HowToHelpFanProps = {
  onCardClick: (index: number) => void;
  selectedIndex: number;
  isInteractive: boolean;
};

export const HowToHelpFan: FC<HowToHelpFanProps> = ({
  onCardClick,
  selectedIndex,
  isInteractive,
}) => {
  const ways = data.waysToHelp;
  return (
    <Fan totalCards={ways.length}>
      {ways.map((w, i) => {
        return (
          <Plx
            animateWhenNotInViewport
            tagName="button"
            aria-label={`${w.heading} - picture shows ${w.imageAltText}`}
            key={w.heading}
            parallaxData={cardPlxData(i, ways.length)}
            style={{
              height: 420,
              position: 'absolute',
              transformOrigin: 'center 360%',
              zIndex: i > 1 ? ways.length - i : i,
              pointerEvents: isInteractive ? 'all' : 'none',
              backgroundColor: 'transparent',
              border: 'none',
            }}
            onClick={isInteractive ? () => onCardClick(i) : undefined}
          >
            <Card
              isSelected={i === selectedIndex}
              isInteractive={isInteractive}
              index={i}
              totalCards={ways.length}
            >
              <Plx
                animateWhenNotInViewport
                parallaxData={imgPlxData(i, ways.length)}
              >
                <Img src={w.image} alt="" /* alt is read by outer button */ />
              </Plx>
              <Plx
                animateWhenNotInViewport
                parallaxData={labelPlxData}
                style={{
                  opacity: 0, // clamp start opacity so you don't see it peek out before it starts
                }}
              >
                <ImgHeading
                  isSelected={i === selectedIndex}
                  isInteractive={isInteractive}
                >
                  {w.heading}
                </ImgHeading>
              </Plx>
            </Card>
          </Plx>
        );
      })}
    </Fan>
  );
};

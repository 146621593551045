import cloneDeep from 'lodash.clonedeep';
import cloneWith from 'lodash.clonewith';
import isPlainObject from 'lodash.isplainobject';
import mapValues from 'lodash.mapvalues';

// responsive helpers
export type plxBreak = 'm' | 'd';

export type PlxBreakProps = {
  bp: plxBreak;
  noPlx?: boolean;
};

// Parses out breakpoint objects like { m: 1000, d: 500 }.
// To fully split between different configs you can pass top-level { m: [...], d: [...]}.
export const responsivePlx = (bp: plxBreak, data, disable?: boolean) => {
  if (disable) return undefined;

  const responsiveVal = (obj) =>
    // falls back from landscape -> desktop
    isPlainObject(obj) ? obj[bp] ?? obj['d'] ?? obj['m'] : undefined;

  const parseResponsive = (obj) => {
    // lodash.clonedeepwith didn't run the predicate recursively, so have to do this part manually
    if (isPlainObject(obj)) {
      return responsiveVal(obj) ?? mapValues(obj, parseResponsive);
    } else if (Array.isArray(obj)) {
      return obj.map((value) => cloneWith(value, parseResponsive));
    }
    return obj;
  };
  // first one is for passing top-level responsive object, no need to clone
  return responsiveVal(data) ?? parseResponsive(data);
};
//

// running total helpers to make adjustments easier
export const makeIncrementer = (startVal = 0) => {
  let current = startVal;
  return (by: number, logWithLabel?: string) => {
    current += by;
    if (logWithLabel) {
      console.info(`${logWithLabel} ${current}`);
    }
    return current;
  };
};

export const makePropertyIncrementer = (
  property: string,
  startVal: number,
  logWithLabel?: string,
) => {
  const current = makeIncrementer(startVal);
  return (by: number) => ({
    property,
    startValue: current(0),
    endValue: current(by, logWithLabel),
  });
};

export const makeValueIncrementer = (
  startVal: number,
  unit = 'px',
  logWithLabel?: string,
) => {
  const current = makeIncrementer(startVal);
  return (by: number) => `${current(by, logWithLabel)}${unit}`;
};
//

export type plxData = {
  m: plxDataGroup[];
  d: plxDataGroup[];
};

export type plxDataGroup = {
  start: number;
  end: number;
  easing?: string;
  properties: plxDataProperties[];
};

export type plxDataProperties = {
  property: string;
  startValue: number;
  endValue: number;
};

export const makeScaledData = (
  data: plxData,
  property: string,
  scale: number,
) => {
  return {
    m: makeScaledDataGroups(data.m, property, scale),
    d: makeScaledDataGroups(data.d, property, scale),
  };
};

export const makeScaledDataGroups = (
  datagroups: plxDataGroup[],
  property: string,
  scale: number,
  startEndScale?: number,
) => {
  const scaled = cloneDeep(datagroups);

  scaled.forEach((d) => {
    if (startEndScale && d.properties.find((p) => p.property === property)) {
      d.start *= startEndScale;
      d.end *= startEndScale;
    }

    d.properties.forEach((p) => {
      if (p.property === property) {
        p.endValue *= scale;
        p.startValue *= scale;
      }
    });
  });

  return scaled;
};

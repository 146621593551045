import React, { FC, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import NavLeaf from '~/images/nav-leaf.svg';

const Container = styled.div`
  position: relative;
`;

const LeafContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  width: 2em;

  svg {
    width: 100%;
  }

  animation: leaf-flip 1.5s;
  animation-fill-mode: forwards;

  @keyframes leaf-flip {
    0% {
      transform: rotate(0deg);
      left: 0;
      opacity: 0;
      transform-origin: center 70%;
      top: -0.5em;
    }
    15% {
      transform-origin: center -20%;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    85% {
      transform-origin: center -40%;
    }
    98% {
    }
    100% {
      transform: rotate(480deg);
      left: 90%;
      opacity: 0;
      transform-origin: center -20%;
      top: -2em;
    }
  }
`;

const Label = styled.a`
  color: ${({ theme }) => theme.color.blue};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: 700;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const Accent = styled.span<{ hovered: boolean }>`
  color: ${(props) =>
    props.hovered ? props.theme.color.green : props.theme.color.blue};
  transition: color 0.5s;
`;

export const handleAnchorClick = (hash: string) => {
  if (hash === '#know-the-facts') {
    const target = document.getElementById('fact-0');
    const height = target ? target.getBoundingClientRect().height : 0;
    const pad = window.innerHeight / 2 - height / 2;
    scrollToElement(hash, pad);
  } else {
    scrollToElement(hash);
  }
};

export const scrollToElement = (querySelector: string, pad?: number) => {
  const target = document.querySelector(querySelector);
  if (target instanceof HTMLElement) {
    // target.focus(); // TODO
    if (!pad) pad = isMobileOnly ? 10 : 20;
    window.scrollTo({
      top: target.getBoundingClientRect().top - pad,
      behavior: 'smooth',
    });
  }
};

type HeaderLinkProps = {
  slug: string;
  label: string;
  labelAccent: string;
};

export const HeaderLink: FC<HeaderLinkProps> = ({
  slug,
  label,
  labelAccent,
}) => {
  const [flippin, setFlippin] = useState(false);
  const flipIt = () => {
    setFlippin(true);
  };
  const flipOff = () => {
    setFlippin(false);
  };

  const AccentedLabel = () => {
    const labelAccentBefore = label.substring(0, label.indexOf(labelAccent));
    const labelAccentAfter = label.substring(
      label.indexOf(labelAccent) + labelAccent.length,
    );
    return (
      <>
        {labelAccentBefore}
        <Accent hovered={flippin}>{labelAccent}</Accent>
        {labelAccentAfter}
      </>
    );
  };

  return (
    <Container onMouseOver={flipIt} onMouseLeave={flipOff}>
      {flippin && (
        <LeafContainer>
          <NavLeaf />
        </LeafContainer>
      )}
      <Label href={`#${slug}`} onClick={() => handleAnchorClick(`#${slug}`)}>
        <AccentedLabel />
      </Label>
    </Container>
  );
};

import React, { FC, useContext } from 'react';
import { isMobile as isMobileDevice } from 'react-device-detect';
import RPlx from 'react-plx';
import { isSSG } from '~/config';
import { IPlxProps } from '~/react-plx';
import { TabbingContext } from '../context/TabbingContext';

type Props = IPlxProps & { onClick?: () => void };

export const Plx: FC<Props> = (props) => {
  const { hasTabbed } = useContext(TabbingContext);

  const getScrollPositionY = () => {
    if (isSSG) return 0;
    const l = document.getElementById('lerp');
    if (l) {
      const i = parseInt(
        l.style.transform.replace('translateY(', '').replace(')', ''),
      );
      if (isNaN(i)) return 0;
      return Math.abs(i);
    }
  };

  // (match w/ pages/index.tsx)
  return isMobileDevice || hasTabbed ? (
    <RPlx {...props} />
  ) : (
    <RPlx
      scrollEventName="lerp-scroll"
      scrollPositionY={getScrollPositionY}
      scrollManager={null}
      {...props}
    />
  );
};

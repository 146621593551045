import React, { FC } from 'react';
import styled from 'styled-components';

const Hamburger = styled.svg<{ isOpen: boolean }>`
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: fill
    ${(props) => (props.isOpen ? '1.5s ease-in' : '0.6s ease-in')};
  fill: ${(props) =>
    props.isOpen ? props.theme.color.offWhite : props.theme.color.blue};
  transition: stroke 1.5s ease-in-out;
  stroke: ${(props) => props.theme.color.offWhite};
  position: relative;
  z-index: 5;
`;

type BurgerProps = {
  open: boolean;
  onClick: () => void;
};

export const Burger: FC<BurgerProps> = ({ open, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: 'transparent', border: 'none' }}
    >
      <Hamburger isOpen={open} viewBox="0 0 100 80">
        {open ? (
          <>
            <line x1="0" y1="0" x2="80" y2="70" strokeWidth="11" />
            <line x1="80" y1="0" x2="0" y2="70" strokeWidth="11" />
          </>
        ) : (
          <>
            <rect y="5" width="100" height="12"></rect>
            <rect y="35" width="100" height="12"></rect>
            <rect y="65" width="50" height="12"></rect>
          </>
        )}
      </Hamburger>
    </button>
  );
};

/* eslint-disable react/jsx-no-undef */
import React, { FC } from 'react';
import styled from 'styled-components';
import { partnerLink } from '~/config/data-types';
import { desktop, tablet } from '~/config/mq';
import { navigateSafe } from '~/util';
import backgroundImg from '../images/footer-bg.png';
import yallLogo from '../images/yall-logo.png';
import { BeatingButton } from './BeatingButton';
import { LinkPlus } from './common';
import { Space } from './common/Space';

const BgContainer = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  width: 100vw;

  // trying to correct for a gap below footer, might be an issue with lerp container
  padding-bottom: 20px;
  margin-bottom: -20px;
`;

const ContentContainer = styled.div`
  transition: all 0.2s ease-in-out;
  width: calc(100% - 40px);
  margin: 20px;
  margin-bottom: 0;
  padding-top: 50vh;

  ${tablet`
    padding-top: 30%;
    width: 90%;
    margin: 30px auto;
    margin-bottom: 0;
  `};
  ${desktop`
    padding-top: 20%;
    width: 80%;
  `};
`;

const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${tablet`
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`;

const LogoContainer = styled.div`
  display: block;
  text-align: center;
  margin-top: 2em;
  ${tablet`
    text-align: left;
    margin-top: 0;
  `};
`;

const PartnerContainer = styled.div`
  display: block;
  text-align: center;
  ${tablet`
    text-align: left;
  `};

  p {
    font-family: ${({ theme }) => theme.font.heading};
    font-size: 24px;
    margin: 0;
    margin-top: 2em;
    line-height: 1;
    ${tablet`
    margin-top: 0;
    line-height: 1.25;
  `};
  }
`;

const Copyright = styled.p`
  font-size: 16px;

  line-height: 1;
  ${tablet`
    margin-bottom: 0;
  line-height: 2;
  `};
`;

const Disclaimer = styled.p`
  width: 100%;
  margin: 0;
  margin-bottom: 1em;
  text-align: center;
  ${tablet`
    width: 90%;
    text-align: left;
  `};
  ${desktop`
    width: 80%;
  `};

  font-size: ${({ theme }) => theme.font.size.xs};
  font-style: italic;
`;

const Logo = styled.img`
  transition: all 0.2s ease-in-out;
  width: 93px;
  height: 55px;

  ${tablet`
    width: 125px;
    height: 74px;
  `}
`;

type FooterProps = {
  //
};

export const Footer: FC<FooterProps> = (props) => {
  return (
    <BgContainer>
      <ContentContainer>
        <MainContent>
          <PartnerContainer>
            <p>Have a heart. Go green.</p>
            <Space size="1.5em" />
            <BeatingButton
              fullOfHeart
              label="y'all want to partner?"
              onClick={() => navigateSafe(partnerLink)}
            />
          </PartnerContainer>

          <LogoContainer>
            <LinkPlus to="https://itsyall.com/">
              <Logo src={yallLogo} alt="y'all logo" />
            </LinkPlus>
            <Copyright>
              &copy; {new Date().getFullYear()} y’all, LLC. Do shit that helps
              people.
            </Copyright>
          </LogoContainer>
        </MainContent>
        <Disclaimer>
          We are in no way affiliated with any company or person in the above
          links, this is just a collection of solid resources!
        </Disclaimer>
      </ContentContainer>
    </BgContainer>
  );
};

import styled from 'styled-components';
import HeartSvg from '~/images/heart.svg';

export const Button = styled.button<{
  buttonHeight: number;
  invertColors?: boolean;
}>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.buttonHeight}rem;
  padding: 0 2.5rem;
  margin: 0 auto;

  text-align: center;
  color: ${(props) =>
    props.invertColors ? props.theme.color.blue : props.theme.color.white};
  font-family: ${({ theme }) => theme.font.body};
  font-size: 1.125rem;
  font-weight: bold;
  font-style: italic;

  background: none;
  border: 0;

  cursor: pointer;

  :hover {
    //
  }
`;

export const Background = styled.span<{
  buttonHeight: number;
  invertColors?: boolean;
}>`
  position: absolute;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;

  background-color: ${(props) =>
    props.invertColors ? props.theme.color.white : props.theme.color.blue};
  border-radius: ${(props) => props.buttonHeight / 2}rem;
`;

export const Thumb = styled.span<{
  buttonHeight: number;
  thumbHeight: number;
  thumbScale: number;
  invertColors?: boolean;
  fullOfHeart?: boolean;
}>`
  pointer-events: none;

  position: absolute;
  left: 0;
  top: ${({ buttonHeight, thumbHeight, thumbScale }) =>
    (buttonHeight - thumbHeight) / 2 +
    -(thumbHeight * thumbScale - thumbHeight) / 2}rem;
  /* z-index: 1; */

  display: block;
  width: ${({ thumbHeight, thumbScale }) => thumbHeight * thumbScale}rem;
  height: ${({ thumbHeight, thumbScale }) => thumbHeight * thumbScale}rem;

  background-color: ${(props) =>
    props.fullOfHeart
      ? 'transparent'
      : props.invertColors
      ? props.theme.color.white
      : props.theme.color.blue};
  border-radius: 50%;
`;

export const Label = styled.span`
  position: relative;
`;

export const Heart = styled(HeartSvg)`
  position: absolute;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;
  margin-top: -11%;

  fill: ${(props) =>
    props.invertColors ? props.theme.color.white : props.theme.color.blue};
`;

import { useThree } from '@react-three/fiber';
import { MotionValue } from 'framer-motion';
import { motion } from 'framer-motion-3d';
import React, { FC } from 'react';
import { PerspectiveCamera as PerspectiveCameraImpl } from 'three';

type CameraProps = {
  makeDefault?: boolean;
  cameraY: MotionValue;
};

export const Camera: FC<CameraProps> = ({ makeDefault, cameraY }) => {
  const set = useThree(({ set }) => set);
  const camera = useThree(({ camera }) => camera);
  const cameraRef = React.useRef<PerspectiveCameraImpl>();

  // Sets camera to default camera
  React.useLayoutEffect(() => {
    if (makeDefault && cameraRef.current) {
      const oldCam = camera;
      set(() => ({ camera: cameraRef.current! }));
      return () => set(() => ({ camera: oldCam }));
    }
  }, [camera, cameraRef, makeDefault, set]);

  return (
    <motion.perspectiveCamera
      // @ts-ignore
      ref={cameraRef}
      // we want the hero to fill width on vertical devices, but reduce on landscape/desktop.
      position-z={10}
      position-y={cameraY}
    />
  );
};

import React, { FC } from 'react';
import styled from 'styled-components';
import { desktop, tablet } from '~/config/mq';
import instaLogo from '../images/Instagram_logo30x30.png';

const InstaCard = styled.div`
  position: relative;
  perspective: 1000px;
  height: 180px;
  width: 180px;
  ${tablet`
    height: 250px;
    width: 250px;
  `}
  ${desktop`
    height: 300px;
    width: 300px;
  `}
  :hover, :active, 
  /* Removed to fix z-index on mobile safari tap (emulator tabbing works without it) -
     :focus-visible,  */
  :focus-within {
    z-index: 9999999999;
    a {
      opacity: 1;
    }
  }

  /* Inner wrapper around front/back */
  .card__content {
    position: relative;
    transition: transform 0.4s;
    transform-style: preserve-3d;
    height: 180px;
    width: 180px;
    ${tablet`
      height: 250px;
      width: 250px;
    `}
    ${desktop`
      height: 300px;
      width: 300px;
    `}
  }

  /* :hover for desktop, :active for touch devices
      translateZ increases 3D "spin" of transition */
  :hover .card__content,
  :active .card__content,
  :focus-within .card__content
  /* Removed to fix mobile safari tap (emulator tabbing works without it) -
     :focus-visible .card__content */ {
    transform: rotateY(0.5turn) scale(1.1) translateZ(-8rem);
    ${desktop`
      transform: rotateY(0.5turn) scale(1.2) translateZ(-8rem);
    `}
  }

  .card__front,
  .card__back {
    border: 5px solid #333;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    display: grid;
    align-content: center;
  }

  /* Hide overflowing lines for long captions */
  .card__body {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    ${tablet`
      -webkit-line-clamp: 7;
    `}
  }

  .card__body,
  a {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.35;
    font-size: 0.785rem;
    ${desktop`
      font-size: 1rem;
      line-height: 1.35;
    `}
  }

  /* @areyallgreen instagram link */
  a {
    position: relative;
    align-self: start;
    display: inline;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    padding: 0.5em 0.75em 0.25em 0;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
    transform: scale(1.1) translateX(1.75rem);
    color: #fff;
    line-height: 1.5;
    ${desktop`
      transform: scale(1);
    `}
  }

  a:hover,
  a:active,
  a:focus-visible {
    transform: scale(1.1) translateX(2rem);
    opacity: 1;
    color: #fff;
  }

  /* Show instagram logo img on hover (or show by default on mobile) */
  a::before {
    content: url(${instaLogo});
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform-origin: right;
    transition: transform 0.25s ease-out;
    transform: translate(-3.35rem, 3px) scale(0.7);
    ${desktop`
      content: '';
      transform: translate(-5rem, 0) scale(0);
    `}
  }

  a:hover::before,
  a:focus-visible::before {
    content: url(${instaLogo});
    transform: translate(-3.5rem, 3px) scale(0.7);
  }

  /* Animate underline on desktop (or show by default on mobile) */
  a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: calc(100% - 0.75em);
    transition: transform 0.25s ease-out;
    background-color: #64b6ca;
    transform: scaleX(1);
    transform-origin: bottom left;
    z-index: -1;
    ${desktop`
      transform: scaleX(0);
      transform-origin: bottom right;
    `}
  }

  a:hover::after,
  a:focus-visible::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .card__back {
    transform: rotateY(0.5turn);
    color: #fff;
    background: #333;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  img {
    height: 170px;
    width: 170px;
    ${tablet`
      height: 240px;
      width: 240px;
    `}
    ${desktop`
      height: 290px;
      width: 290px;
    `}
  }
`;

export type CardProps = {
  img: string;
  caption?: string;
};

export const Card: FC<CardProps> = ({ img, caption }) => {
  return (
    <InstaCard tabIndex={0}>
      <div className="card__content">
        <div className="card__front">
          <img src={img} alt={caption || 'Instagram post'} />
        </div>

        <div className="card__back">
          <p className="card__body">{caption}</p>
          <a
            href="https://www.instagram.com/areyallgreen/"
            target="_blank"
            rel="noopener noreferrer"
            tabIndex={0}
          >
            @areyallgreen
          </a>
        </div>
      </div>
    </InstaCard>
  );
};

export default Card;

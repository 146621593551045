import { AnimatePresence, motion } from 'framer-motion';
import shuffle from 'lodash.shuffle';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { isSSG } from '~/config';
import { useHasMounted } from '../../hooks/useHasMounted';
import {
  Background,
  Badge,
  Bear,
  Clouds,
  Deer,
  getPreloadBeat,
  Heart,
  Icons,
  Squirrel,
  Tree,
  Wrapper,
} from './styles';

const animals = shuffle([Squirrel, Deer, Bear, Tree, Clouds]);

export type PreloaderProps = {
  loading: boolean;
};

const Preloader: FC<PreloaderProps> = ({ loading = true }) => {
  const hasMounted = useHasMounted(); // avoids animation glitches from drawing too soon
  const [isRemoved, setIsRemoved] = useState(false);
  const [tick, setTick] = useState(0);

  useEffect(() => {
    if (!isSSG) {
      // Workaround for style not getting set if presence is triggered via same state.
      // This allows the style to be set first, then presence on the next update.
      setIsRemoved(!loading);

      // lock page scroll during preload (released in handleExited)
      if (loading) {
        document.body.style.overflow = 'hidden';
      }
    }
  }, [loading]);

  // In case of super-long loads, `tick` changes key on animals to force rerender.
  // To test this, in pages/index.tsx set the useListComplete hook's minSeconds to a high number.
  useEffect(() => {
    const timer =
      hasMounted &&
      setInterval(() => {
        setTick(tick + 1);
      }, getPreloadBeat(animals.length) * 1000);

    return () => {
      if (typeof timer === 'number') {
        clearInterval(timer);
      }
    };
  }, [tick, hasMounted]);

  // restore scrollbar when zoom completes
  const handleExited = useCallback(() => {
    if (!isSSG) {
      document.body.style.overflow = 'visible';
    }
  }, []);

  return (
    <AnimatePresence onExitComplete={handleExited}>
      {!isRemoved && (
        <motion.div
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: 'easeIn' }}
        >
          <Wrapper className={loading ? 'loading' : ''}>
            <Background />
            {hasMounted && (
              <>
                <Badge>
                  <Heart />
                </Badge>
                <Icons>
                  {animals.map((Comp, i) => (
                    <Comp key={`animal-${tick}-${i}`} />
                  ))}
                </Icons>
              </>
            )}
          </Wrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Preloader.displayName = 'Preloader';

export { Preloader };

import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Generic keyed list completion with option for minimum time, e.g. for a preload list.
 *
 * Pass your keys and an optional time, then call `completeForKey` for each key. Duplicate calls are ok.
 * `isListComplete` will return true when time has elapsed and all keys are complete.
 *
 * @param keys **Non-reactive (consumed only once)** Array of keys passable to `completeForKey`
 * @param minSeconds **Non-reactive (consumed only once)** Minimum seconds before `isListComplete` can be true.
 *
 * @returns {
 *  completeForKey: callback that accepts one of the keys
 *  isListComplete: boolean
 * }
 */
export const useListComplete = (
  keys: string[] /* consumed once only */,
  minSeconds = 0,
) => {
  const timer = useRef<NodeJS.Timeout>();
  const loads = useRef(
    keys.reduce((prev, cur) => ({ ...prev, [cur]: false }), {}),
  );

  const [isListComplete, setIsLoaded] = useState(false);
  const timerDone = useRef(minSeconds === 0);

  const checkReady = useCallback(() => {
    if (timerDone.current && Object.values(loads.current).every((v) => v)) {
      timer.current && clearTimeout(timer.current);
      setIsLoaded(true);
    }
  }, []);

  const completeForKey = useCallback(
    (key: string) => {
      if (!loads.current[key]) {
        loads.current[key] = true;
        checkReady();
      }
    },
    [checkReady],
  );

  useEffect(
    () => {
      if (minSeconds > 0) {
        timer.current = setTimeout(() => {
          timerDone.current = true;
          checkReady();
        }, minSeconds * 1000);

        return () => timer.current && clearTimeout(timer.current);
      }
    },
    // run once only - this hook's inputs are non-reactive
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { completeForKey, isListComplete };
};

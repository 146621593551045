import React, { FC } from 'react';
import styled from 'styled-components';
import { Plx } from '~/components/Plx';
import { data } from '~/config/data-types';
import { desktop, tablet } from '~/config/mq';
import { PlxBreakProps, responsivePlx } from '~/util/plx';
import LogoSVG from '../images/AreYallGreen-Logos-Final.svg';
import { Burger } from './Burger';
import { HeaderLink } from './HeaderLink';

const headerPlxData = [
  {
    start: 0,
    end: 1000,
    properties: [
      {
        property: 'translateY',
        startValue: 0,
        endValue: 500,
      },
    ],
  },
];

// -- styles --

const Container = styled.div`
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
`;

const InnerContainer = styled.div`
  width: calc(100% - 40px);
  margin: 20px;
  display: grid;
  grid-template-columns: [left] auto 1fr [right] auto;
  align-items: center;
  pointer-events: all !important;

  ${tablet`
    width: 90%;
    margin: 30px auto;
  `}

  ${desktop`
    width: 80%;
  `}
`;

const Logo = styled(LogoSVG)`
  transition: all 0.2s ease-in-out;
  grid-column: left;
  width: 93px;
  height: 55px;

  ${tablet`
    width: 145px;
    height: 82px;
  `}
`;

const MenuContainer = styled.div`
  grid-column: right;
`;

const DesktopMenu = styled.nav`
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 45px;
`;

type HeaderProps = PlxBreakProps & {
  toggleMenu: () => void;
  menuOpen: boolean;
};

export const Header: FC<HeaderProps & { isMobile: boolean }> = ({
  toggleMenu,
  menuOpen,
  bp,
  isMobile,
}) => {
  const content = (
    <InnerContainer>
      <Logo />
      <MenuContainer>
        {isMobile ? (
          <Burger onClick={toggleMenu} open={menuOpen} />
        ) : (
          <DesktopMenu>
            {data.mainLinks.map((item) => (
              <HeaderLink key={item.label} {...item} />
            ))}
          </DesktopMenu>
        )}
      </MenuContainer>
    </InnerContainer>
  );

  return isMobile ? (
    <Container>{content}</Container>
  ) : (
    <Container>
      <Plx
        animateWhenNotInViewport // workaround for bug where opacity doesn't restore on scroll-up
        parallaxData={responsivePlx(bp, headerPlxData)}
      >
        {content}
      </Plx>
    </Container>
  );
};

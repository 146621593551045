import React, { FC } from 'react';
import styled from 'styled-components';
import { WayToHelpLink as WayToHelpLinkType } from '~/config/data-types';
import LinkBox from '../../images/link-box.svg';
import { LinkPlus } from '../common';

const Container = styled.div`
  scroll-snap-align: center;
  padding: 0 20px;
  width: 100%;
  height: 100%; // needed on both
`;

const BG = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  height: 320px; // needed on both
  overflow: scroll;
  padding: 30px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: flex-start;
`;

const Left = styled.div`
  grid-column: 1;
  justify-items: start;
  text-align: left;
`;

const Right = styled.div`
  grid-column: 2;
  padding-left: 20px;
`;

const LinkItem = styled.p`
  color: ${({ theme }) => theme.color.blue};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: ${({ theme }) => theme.font.size.md};
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.25;
  padding-bottom: 30px;
`;

type WayToHelpMobileProps = WayToHelpLinkType;

export const WayToHelpMobile: FC<WayToHelpMobileProps> = ({
  title,
  description,
  url,
}) => {
  return (
    <Container>
      <BG>
        <Left>
          <LinkItem>
            <LinkPlus to={url}>{title}</LinkPlus>
          </LinkItem>
          <Text>{description}</Text>
        </Left>
        <Right>
          <LinkPlus to={url}>
            <LinkBox />
          </LinkPlus>
        </Right>
      </BG>
    </Container>
  );
};

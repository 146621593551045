import { useProgress } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import React, {
  CSSProperties,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { isDesktop } from 'react-device-detect';
import { isSSG } from '~/config';
import { TabbingContext } from '~/context/TabbingContext';
import { Scene } from '../Scene';

type Hero3DProps = {
  onLoad?: () => void;
  /** uses to wait for top-level preload to complete before showing */
  hold?: boolean;
};

export const Hero3D: FC<Hero3DProps> = ({ onLoad, hold }) => {
  const loadTimeout = useRef<NodeJS.Timeout>();
  const isLoaded = useRef(false);
  const isActive = useRef(false);
  const isInView = useRef(true);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { hasTabbed } = useContext(TabbingContext);

  // Must be set to non-zero so transform fires when ref is set
  const scrollY = useMotionValue(-1);
  const handleTransform = useCallback(() => {
    if (containerRef.current) {
      const { top, height } = containerRef.current.getBoundingClientRect();

      if (window.innerHeight > top) {
        return Math.min(
          (window.innerHeight - top) / (height + window.innerHeight),
          1,
        );
      }
    }
    return 0;
  }, []);

  const percentScrolled = useTransform(scrollY, handleTransform);

  const handleScroll = useCallback(
    (event?) => {
      if (!isSSG && isInView.current) {
        const scrollPosition = event?.detail.scrollPosition ?? 0;
        scrollY.set(scrollPosition);
        return;
      }
      scrollY.set(window.scrollY);
    },
    [scrollY],
  );

  useEffect(() => {
    // no lerp on mobile or after tabbing (see index.tsx, Plx.tsx)
    const eventName = isDesktop && !hasTabbed ? 'lerp-scroll' : 'window-scroll';
    window.addEventListener(eventName, handleScroll);

    return () => {
      window.removeEventListener(eventName, handleScroll);
    };
  }, [handleScroll, hasTabbed]);

  const handleProgress = useCallback(
    (state) => {
      if (state.progress === 100 && !isLoaded.current) {
        loadTimeout.current && clearTimeout(loadTimeout.current);
        onLoad?.();
        isLoaded.current = true; // next: see isActive effect below
      }
    },
    [onLoad],
  );

  useEffect(
    () => {
      // if preload is being monitored, kill out after 5s to avoid blocking the page
      if (onLoad) {
        loadTimeout.current = setTimeout(() => {
          handleProgress({ progress: 100 });
        }, 5000);
        return () => {
          loadTimeout.current && clearTimeout(loadTimeout.current);
        };
      }
    },
    // once only!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Preload
  useProgress(handleProgress);

  // Show, finally, when app is preloaded- this timing was needed for the initial frame
  // layout to take on mobile safari
  useEffect(() => {
    if (!hold && !isActive.current) {
      isActive.current = true;
      handleScroll(); // simulate initial scroll event
      percentScrolled.set(handleTransform());
    }
  }, [handleScroll, handleTransform, hold, percentScrolled]);

  const wrapperDivProps = useMemo(() => {
    return {
      variants: {
        hold: { opacity: 0 },
        show: { opacity: 1, transition: { duration: 0.5 } },
      },
      initial: 'hold',
      animate: hold ? 'hold' : 'show',
      onViewportEnter: () => (isInView.current = true),
      onViewportLeave: () => (isInView.current = false),
      style: {
        // oversized so it doesn't get cut off on mobile sizes during scroll
        height: '120vw',
        width: '120vw',
        marginLeft: '-10vw',
        pointerEvents: 'none',
      } as CSSProperties,
    };
  }, [hold]);

  const cloudY = useTransform(
    scrollY,
    [0, 2000],
    isDesktop ? [-300, 200] : [-69, 0],
    {},
  );
  const cloudOpacity = useTransform(scrollY, [1300, 2000], [1, 0], {});

  const cloudDivProps = useMemo(
    () => ({
      style: {
        position: 'absolute',
        y: cloudY,
        opacity: cloudOpacity as unknown,
        width: '100%',
      } as CSSProperties,
    }),
    [cloudOpacity, cloudY],
  );

  return (
    <>
      <motion.div {...cloudDivProps}>
        <StaticImage
          src="../../images/clouds/canvas.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
        />
      </motion.div>
      <motion.div {...wrapperDivProps} ref={containerRef}>
        <Canvas style={{ pointerEvents: 'none' }}>
          <Scene percentScrolled={percentScrolled} />
        </Canvas>
      </motion.div>
    </>
  );
};

import d from '../data/data.json';

export type PageData = {
  mainLinks: MainLink[];
  facts: Fact[];
  waysToHelp: WayToHelp[];
};

export type MainLink = {
  slug: string;
  label: string;
  labelAccent: string;
};

export type Fact = {
  bigText: string;
  text: string;
  greenText: string;
  url: string;
};

export type WayToHelp = {
  heading: string;
  subhead: string;
  text: string;
  image: string;
  imageAltText: string;
  links: WayToHelpLink[];
};

export type WayToHelpLink = {
  title: string;
  description: string;
  url?: string;
};

export const data: PageData = d;
export const slugs = data.mainLinks.map((item) => item.slug);

export const partnerLink = 'mailto:info@itsyall.com';

import React, { FC, ReactNode, useCallback, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { isSSG } from '~/config';
import { useRaf } from '~/hooks';

const ScrollParent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  pointer-events: ${isMobileOnly ? 'auto' : 'none'};
`;

type LerpScrollProps = {
  ease?: number; // default 0.1
  children?: ReactNode;
};

const LerpScroll: FC<LerpScrollProps> = ({ children, ease = 0.1 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const data = useRef({
    scrollY: 0,
    rounded: 0,
    prevWindowHeight: 0,
    prevLerpHeight: 0,
    sentInitial: false,
  });

  const update = useCallback(() => {
    if (isSSG || !ref.current) {
      return;
    }

    // 1. Dispatch lerp-scroll
    const delta = window.scrollY - data.current.scrollY;
    const newY = data.current.scrollY + delta * ease;
    ref.current.style.transform = `translateY(-${newY.toFixed(2)}px)`;
    if (Math.abs(delta) > 1 || !data.current.sentInitial) {
      const event = new CustomEvent('lerp-scroll', {
        detail: {
          // Alias for scrollPositionY for backwards compatibility
          scrollPosition: newY,
          scrollPositionY: newY,
          scrollPositionX: 0,
        },
      });
      window.dispatchEvent(event);
    }
    data.current.scrollY = newY;

    // 2. Update & dispatch main scroll element height change
    const height = ref.current.getBoundingClientRect().height;
    if (
      height !== data.current.prevLerpHeight ||
      window.innerHeight !== data.current.prevWindowHeight ||
      !data.current.sentInitial
    ) {
      document.body.style.height = `${height}px`;
      data.current.prevLerpHeight = height;
      data.current.prevWindowHeight = window.innerHeight;
      window.dispatchEvent(new CustomEvent('lerp-resize'));
    }

    data.current.sentInitial = true;
  }, [ease]);

  useRaf(true, update);

  return (
    <ScrollParent id="parent" tabIndex={-1}>
      <div
        id="lerp"
        tabIndex={-1}
        ref={ref}
        style={{ position: 'relative', pointerEvents: 'all' }}
      >
        {children}
      </div>
    </ScrollParent>
  );
};

export default LerpScroll;

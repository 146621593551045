import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import InView from 'react-intersection-observer';
import Lottie from 'react-lottie-player';
import styled from 'styled-components';
import animationData from '~/data/flappy-bird.json';
import { useRaf, useResize } from '~/hooks';

type BlackbirdProps = {
  seed?: number;
  size: number;
  bottomOffset?: number;
};

export const Blackbird: FC<BlackbirdProps> = ({
  seed = 0,
  size,
  bottomOffset = 0,
}) => {
  const [isActive, setActive] = useState(false);
  const { viewportWidth, viewportHeight, scrollHeight } = useResize();
  const ref = useRef<HTMLDivElement>(null);
  const data = useRef({
    x: 0,
    y: 0,
    xOffset: 0,
    yOffset: 0,
    xVel: 2,
    xShift: 0,
    c: 0,
    xLock: false,
    prevSizeCheck: 0,
  });

  const update = useCallback(() => {
    if (!viewportWidth || !scrollHeight || !ref.current) {
      return;
    }
    const hFloat = 2; // sine wave multiplier
    const vFloat = 100; // sine wave multiplier
    const yDamp = 0.01; // cursor follow damping
    const d = { ...data.current };
    const vw = viewportWidth;
    const edgeTol = vw * 0.15;

    // initial setup (early return above ensures we have window size)
    if (d.x === 0 && d.y === 0) {
      d.x = (seed * 500) % vw;
      d.xVel =
        Math.max(1, d.xVel - seed) * (seed % 2 ? 1 : -1) * ((700 - vw) * 0.002);
      d.c = seed * 20;
    }

    // no longer doing vertical follow here since it's just stuck on footer
    const sizeCheck = scrollHeight * viewportWidth * viewportHeight;
    if (d.prevSizeCheck !== sizeCheck) {
      d.y = data.current.y = scrollHeight - bottomOffset + seed * 25;
      d.prevSizeCheck = sizeCheck;
    }

    // vertical position is a damped mouse follow plus sine wave
    d.c++; // running counter
    d.yOffset = Math.sin((d.c * Math.PI) / 180) * vFloat;
    d.y += (data.current.y - d.y) * yDamp;

    // horizontal position is an incrementer that reverses direction randomly within bounds
    // combined with a sine wave to provide back-and-forth drifting effect
    d.xShift = Math.sin((d.c * Math.PI) / 180) * hFloat;
    d.x += d.xShift * d.xVel;
    d.x = Math.max(Math.min(d.x, vw), 0);

    // detect left and right edges when moving toward them, reverse direction and lock change
    if (
      !d.xLock &&
      ((d.xVel < 0 && d.x < edgeTol) || (d.xVel > 0 && vw - d.x < edgeTol))
    ) {
      d.xLock = true;
      d.xVel *= -1;
    }
    // once locked, randomize occasional reversal after having moved away from edge enough
    else if (
      d.xLock &&
      ((d.xVel > 0 && d.x > vw / 3) || (d.xVel < 0 && d.x < vw / 3)) &&
      Math.random() < 0.005
    ) {
      d.xLock = false;
      d.xVel *= -1;
    }

    // angle
    const angle = Math.round(Math.abs(vw / 2 - d.x / 1.5) * 0.05);

    // apply
    ref.current.style.top = `${d.y + d.yOffset}px`;
    ref.current.style.left = `${d.x}px`;
    ref.current.style.transform = `rotate(${angle}deg)`;

    Object.assign(data.current, d);
  }, [viewportWidth, scrollHeight, viewportHeight, seed, bottomOffset]);

  useRaf(true, update, 2);

  // staggers the lotties so they're not synced
  useEffect(() => {
    const delay = setTimeout(() => setActive(true), seed * 1000);
    return () => clearTimeout(delay);
  }, [seed]);

  return (
    <Container isActive={isActive} scrollHeight={scrollHeight}>
      <div ref={ref} style={{ position: 'absolute' }}>
        {/* perf optimization: don't run lottie animation when not in view */}
        <InView>
          {(fields) => (
            <div ref={fields.ref}>
              <Lottie
                play={fields.inView}
                animationData={animationData}
                style={{ width: size, height: size }}
              />
            </div>
          )}
        </InView>
      </div>
    </Container>
  );
};

const Container = styled.div<{ scrollHeight: number; isActive: boolean }>`
  pointer-events: none;
  position: absolute;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: ${(props) => props.scrollHeight}px;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.2s linear;
`;

import { Link } from 'gatsby';
import React, { FC, ReactNode } from 'react';
import { isExternalUrl, makeKey } from '~/util';

type LinkPlusProps = {
  to?: string; // can be omitted if you're passing `onClick`
  className?: string;
  onClick?: (e?) => void;

  /**
   * For normal cases just pass children. This supports titles from JSON.
   */
  title?: string;
  /**
   * Renders inside the link and can wrap or replace the
   * generated title element(s)
   */
  wrapper?: (titleChildren: ReactNode) => ReactNode;
  children?: ReactNode;
};

/**
 * Gatsby Link (internal) or external (<a>) link.
 *
 * For normal cases just pass children for literal cases. If you're rendering
 * links from JSON data, you can use the `title` and `wrapper` props instead,
 * which include support line-break support via `\n`'s in the title string.
 *
 * The spl002 project extends this with additional functionality that can be ported:
 * - transition-before-trigger ExitContext
 * - CTA components that wrap this simple base component to create icon buttons, etc.
 */
export const LinkPlus: FC<LinkPlusProps> = ({
  to,
  title,
  className,
  onClick,
  wrapper,
  children,
}) => {
  let titleJSX: ReactNode = children || title;

  // Multi-line title: break into divs with classNames `link-title-line-1`, etc.
  if (!children && title?.includes('\n')) {
    titleJSX = title.split('\n').map((line, i) => (
      <div key={makeKey(line)} className={`link-title-line-${i + 1}`}>
        {line}
      </div>
    ));
  }

  if (wrapper) {
    titleJSX = wrapper(titleJSX);
  }

  // If no style is passed in, this default prevents cta containers from expanding out
  const defaultStyle = className ? undefined : { display: 'inline-block' };

  const link = to || '#';
  if (isExternalUrl(link)) {
    return (
      <a
        style={defaultStyle}
        className={className}
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={onClick}
      >
        {titleJSX}
      </a>
    );
  }

  return (
    <Link
      style={defaultStyle}
      className={className}
      to={link}
      onClick={onClick}
    >
      {titleJSX}
    </Link>
  );
};

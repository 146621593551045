import React, {
  Children,
  cloneElement,
  CSSProperties,
  FC,
  isValidElement,
  ReactNode,
} from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';
import { Transition } from 'react-transition-group';
import { usePageExit } from './page-exit';

const defaults = {
  seconds: 0.3,
  enterEasing: 'ease-in',
  enterEasingShift: 'ease-out',
  exitEasing: 'ease-out',
  intersectionOptions: { triggerOnce: true },
};

export type FadeProps = {
  // -- Animation Props --

  seconds?: number;
  delaySeconds?: number;
  enterEasing?: string;
  exitEasing?: string;
  /**
   * Stagger effect for multiple children. Value is a percent of fade duration.
   *
   * Examples:
   * - 1: back-to-back
   * - 0.8: slight overlap
   * - 1.2: extra delay between each item
   */
  stagger?: number;

  // Addtional options for fade-and-___ transitions.
  // Note that Fade uses an in / reverse-out paradigm for simplicity,
  // if you need more detail use react-transition-group directly.
  xShift?: number; // start only
  yShift?: number; // start only
  xScale?: number; // start only
  yScale?: number; // start only
  customTransform?: string; // start only
  customStyles?: CSSProperties; // start only

  // -- Trigger Props --

  /**
   * State-based trigger. Even when true, fade will start when the component is scrolled
   * into view, which can be overridden using `intersectionOptions`.
   * @default true
   * @see intersectionOptions
   */
  isShowing?: boolean;
  /**
   * By default Fade always waits until its first element is in view to start.
   *
   * To disable the observer pass `skip: true`.
   *
   * To always replay effects on scroll up/down pass `triggerOnce: false`.
   * (Fade defaults this option to true.)
   *
   * Other options like `threshold` can also fine-tune the observer.
   *
   * @default {triggerOnce:false}
   * @see https://www.npmjs.com/package/react-intersection-observer#options
   */
  intersectionOptions?: IntersectionOptions;

  // -- Advanced Props --

  callbacks?: Partial<{
    onEnter: () => void;
    onEntering: () => void;
    onEntered: () => void;
    onExit: () => void;
    onExiting: () => void;
    onExited: () => void;
  }>;
  /**
   * Page transitions: automates fade-out when one of the page-exit functions
   * was used to wrap a link. Note that this is ONE WAY and made for one-time
   * page content, not persistent elements like nav items!
   * @see page-exit.ts
   */
  withPageExit?: boolean;
  /**
   * Modifies the timeout value passed to the Transition
   */
  timeout?: number;
  children?: ReactNode;
};

/**
 * version: 2.0.0
 *
 * Fade with extras like shift, scale, and stagger, plus built-in
 * interesection-observer functionality to start play when in view.
 *
 * By default children are cloned to add style props, which helps
 * with lists, such as:
 *
 * <ul>
 *   <Fade stagger={0.5}>
 *     <li>...</li>
 *     <li>...</li>
 *     <li>...</li>
 *   </Fade>
 * </ul>
 *
 * However in some cases (like functional component or string children),
 * extra wrapper divs are added. Avoid this by adding your own div around
 * the child.
 */
const Fade: FC<FadeProps> = ({
  isShowing = true,
  intersectionOptions,
  seconds = defaults.seconds,
  delaySeconds = 0,
  enterEasing, // default is set below
  stagger,
  exitEasing = defaults.exitEasing,
  xShift = 0,
  yShift = 0,
  xScale = 1,
  yScale = 1,
  customTransform,
  customStyles,
  children,
  callbacks,
  timeout,
  withPageExit = false,
}) => {
  const { isExitingPage } = usePageExit(withPageExit);

  const options: IntersectionOptions = intersectionOptions
    ? { ...defaults.intersectionOptions, ...intersectionOptions }
    : defaults.intersectionOptions;
  const { ref: inViewRef, inView } = useInView(options);

  const hasShift = !!xShift || !!yShift;
  const _enterEasing =
    enterEasing ||
    (hasShift ? defaults.enterEasingShift : defaults.enterEasing);

  // Build transforms - please preserve the preceding spaces in strings
  let startTransform = '';
  let endTransform = '';
  if (hasShift) {
    startTransform += `translate(${xShift}px, ${yShift}px)`;
    endTransform += 'translate(0, 0)';
  }

  const hasScale = (!!xScale && xScale !== 1) || (!!yScale && yScale !== 1);
  if (hasScale) {
    startTransform += ` scale(${xScale}, ${yScale})`;
    endTransform += ' scale(1, 1)';
  }
  if (customTransform) {
    startTransform += ` ${customTransform}`;
    endTransform += ` ${customTransform}`;
  }

  const startOpacity = 0;
  const endOpacity = 1;
  // Only apply transforms if there actually are any -- otherwise, you risk boxing in any position:absolute children
  const transitionStyles = {
    entering: {
      opacity: startOpacity,
      transform: startTransform,
      ...customStyles,
    },
    entered: {
      opacity: endOpacity,
      transform: endTransform,
    },
    exiting: {
      opacity: startOpacity,
      transform: startTransform,
      ...customStyles,
    },
    exited: {
      opacity: startOpacity,
      transform: startTransform,
    },
  };

  const _in = !isExitingPage && isShowing && (options.skip || inView);
  return (
    <Transition
      in={_in}
      mountOnEnter={false} // Don't set true, that adds in-view complexity,
      unmountOnExit={false} // and objects usually should hold their size.
      timeout={
        timeout !== undefined ? timeout : (seconds + delaySeconds) * 1000
      }
      {...callbacks}
    >
      {(state) => {
        const isEnter = state.includes('enter');
        const styleWithFade = (style = {}, i?: number) => ({
          ...style,
          transform: endTransform,
          transition: `all`,
          transitionTimingFunction: `${isEnter ? _enterEasing : exitEasing}`,
          transitionDuration: `${seconds}s`,
          transitionDelay:
            stagger === undefined || i === undefined
              ? `${delaySeconds}s`
              : `${delaySeconds + i * seconds * stagger}s`,
          ...transitionStyles[state],
        });

        let refAdded = false;
        return Children.map(children, (child, i) => {
          let ref = !refAdded ? inViewRef : undefined;
          const count = React.Children.count(children);
          if (isValidElement(child)) {
            if (typeof child.type !== 'function') {
              // @ts-ignore
              if (!child.ref) {
                refAdded = true;
              } else if (!refAdded) {
                ref = undefined;
                if (i === count - 1) {
                  console.error(
                    `Fade could not add intersection observer ref to ${child.type} node. ` +
                      `Be sure there's at least one child without a ref.`,
                    new Error().stack,
                  );
                }
              }
              return cloneElement(
                child,
                {
                  ...child.props,
                  style: styleWithFade(child.props.style, i),
                  ref,
                },
                child.props?.children,
              );
            } else if (child.type?.name === 'Fade') {
              // Nested Fade instance: ignore
              return child;
            } else {
              // Do nothing. Custom FC can't receive a ref directly.
            }
          }

          // Add a fade style wrapper, e.g. custom FC or text-only
          // TODO will we need ability to style this?
          refAdded = true;
          return (
            <div key={`.${i}`} style={styleWithFade({}, i)} ref={ref}>
              {child}
            </div>
          );
        });
      }}
    </Transition>
  );
};

export { Fade };

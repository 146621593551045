import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { usePointer, useRaf, useResize, useTabOutline } from '~/hooks';
import { clamp } from '~/util/math';
import { Background, Button, Heart, Label, Thumb } from './styles';

export type BeatingButtonProps = {
  label: string;
  onClick?: () => void;
  invertColors?: boolean;
  fullOfHeart?: boolean;
  wearOnSleeve?: boolean; // makes the heart show prior to rollover
};

export const BeatingButton: FC<BeatingButtonProps> = React.memo(
  ({
    label,
    onClick,
    invertColors = false,
    fullOfHeart = false,
    wearOnSleeve = false,
  }) => {
    const BPM = 60;
    const BPM_MAX = 180;
    const BUTTON_HEIGHT = 3; // rem
    const THUMB_HEIGHT = BUTTON_HEIGHT - (fullOfHeart ? 0.8 : 0.2); // Reduce slightly to prevent edges peeking through
    const THUMB_SCALE = fullOfHeart ? 2.4 : 1.7; // scale factor of BUTTON_HEIGHT
    const BEAT_SCALE = 0.4;

    const { hideOutlineStyle } = useTabOutline();
    const [inViewRef, isInView] = useInView();
    const [hasInteracted, setHasInteracted] = useState(false);

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const thumbRef = useRef<HTMLButtonElement>(null);
    const buttonBounds = useRef({ left: 0, top: 0, width: 0, height: 0 });

    const animationData = useRef({
      isHovering: wearOnSleeve,
      hasMoved: false,
      thumbX: 0.5,
      thumbScale: 0,
      beatScaleTarget: 0,
      beatScale: 0,
      beatTimer: 0,
      intensity: 0,
      heartRotation: 0,
    });

    const handleMouseEnter = () => {
      setHasInteracted(true);
      animationData.current.isHovering = true;
      animationData.current.beatTimer = 500;
    };
    const handleMouseLeave = () => {
      animationData.current.isHovering = false;
      animationData.current.beatTimer = 0;
    };
    const handleTouchStart = () => handleMouseEnter();
    const handleTouchEnd = () => handleMouseLeave();

    const onResize = useCallback(() => {
      if (buttonRef.current) {
        buttonBounds.current = buttonRef.current.getBoundingClientRect();
      }
    }, []);

    useResize(onResize);

    const pointerData = usePointer(isInView);

    // Animation
    // ---------

    const onRaf = useCallback(
      ({ delta }) => {
        const { clientX, deltaX } = pointerData.current;
        const { width, height, left } = buttonBounds.current;
        const { isHovering, thumbScale, thumbX } = animationData.current;

        // allows thumb to stay centered in wearOnSleeve mode until mouse is present
        if (!animationData.current.hasMoved && clientX > 0) {
          animationData.current.hasMoved = true;
        }

        if (animationData.current.hasMoved) {
          const targetX = clamp((clientX - left) / width, 0, 1);
          animationData.current.thumbX += (targetX - thumbX) * 0.1;
        }

        const targetScale = isHovering ? 1 : 0;
        const scaleEase = !fullOfHeart ? 0.1 : isHovering ? 0.1 : 0.18;
        animationData.current.thumbScale +=
          (targetScale - thumbScale) * scaleEase;

        // Apply rotation to heart
        if (fullOfHeart) {
          animationData.current.heartRotation += deltaX / width;
          animationData.current.heartRotation = clamp(
            animationData.current.heartRotation,
            -1,
            1,
          );
        }

        if (thumbRef.current) {
          const rangeX =
            width - height * (THUMB_HEIGHT / BUTTON_HEIGHT) * THUMB_SCALE;
          const translateX = thumbX * rangeX;
          const scale =
            1 / THUMB_SCALE +
            animationData.current.thumbScale *
              (1 - 1 / (THUMB_SCALE - BEAT_SCALE)) +
            animationData.current.beatScale *
              BEAT_SCALE *
              animationData.current.thumbScale;
          const rotation = fullOfHeart
            ? 50 * animationData.current.heartRotation
            : 0;

          thumbRef.current.style.transform = `translate3d(${translateX}px, 0, 0) scale(${scale}) rotate(${rotation}deg)`;
        }

        // Beating
        const bpm = BPM + (BPM_MAX - BPM) * animationData.current.intensity;
        // console.log(bpm);
        const beatPeriod = (1000 * 60) / bpm;
        animationData.current.beatTimer += Math.min(delta, 1000 / 30);

        if (animationData.current.beatTimer >= beatPeriod) {
          // Fire a beat
          animationData.current.beatScaleTarget = 1;
          animationData.current.beatTimer -= beatPeriod;
          // Add a follow up beat
          setTimeout(
            () => (animationData.current.beatScaleTarget += 0.5),
            beatPeriod * 0.4,
          );
        }

        animationData.current.beatScale +=
          (animationData.current.beatScaleTarget -
            animationData.current.beatScale) *
          0.1;

        // Decay beat scale back to resting
        animationData.current.beatScaleTarget *= 0.85;

        // Decay heart rotation back to resting
        if (fullOfHeart) {
          animationData.current.heartRotation *= 0.93;
        }

        // Increase heart rate when frantically moving pointer within button area
        if (isHovering) {
          animationData.current.intensity += (Math.abs(deltaX) / width) * 0.5;
          animationData.current.intensity = clamp(
            animationData.current.intensity,
            0,
            1,
          );
        }
        // Decay intensity back down to resting
        animationData.current.intensity *= 0.99;
      },
      [THUMB_HEIGHT, THUMB_SCALE, pointerData, fullOfHeart],
    );

    useRaf(isInView && hasInteracted, onRaf, 2);

    useEffect(
      () => onRaf({ delta: 0 }),
      // once only!
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    // ---

    const setButtonRefs = useCallback(
      (node) => {
        if (buttonRef) {
          buttonRef.current = node;
        }
        inViewRef(node);
      },
      [inViewRef],
    );

    return (
      <Button
        ref={setButtonRefs}
        // disabled={!onClick}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        style={hideOutlineStyle}
        buttonHeight={BUTTON_HEIGHT}
        invertColors={invertColors}
      >
        <Thumb
          ref={thumbRef}
          buttonHeight={BUTTON_HEIGHT}
          thumbHeight={THUMB_HEIGHT}
          thumbScale={THUMB_SCALE}
          invertColors={invertColors}
          fullOfHeart={fullOfHeart}
        >
          {fullOfHeart && <Heart />}
        </Thumb>
        <Background buttonHeight={BUTTON_HEIGHT} invertColors={invertColors} />
        <Label>{label}</Label>
      </Button>
    );
  },
);
BeatingButton.displayName = 'BeatingButton';

import 'intersection-observer'; // polyfill
import React, {
  createContext,
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';

export const IsVisibleContext = createContext<{ isVisible: boolean }>({
  isVisible: true,
});

type ContentHiderProps = {
  hidden: boolean;
  /**
   * Default `0.5`
   * Seconds to delay hiding, e.g. to wait for a mobile menu to show
   */
  hideDelay?: number;
  /**
   * Default `0.5`
   * Seconds to delay showing, e.g. to wait for a mobile menu to hide
   */
  showDelay?: number;
  /**
   * Defaults to 'display: block' but you can override that in this object if you wish. */
  style?: CSSProperties;
  children: ReactNode;
};

/**
 * Hide content so it isn't tabbable, such as when mobile menu is showing.
 *
 * Uses `display: none`.
 */
export const ContentHider: FC<ContentHiderProps> = ({
  hidden = false,
  hideDelay = 0.5,
  showDelay = 0.5,
  style,
  children,
}) => {
  const [isHidden, setIsHidden] = useState(hidden);

  useEffect(() => {
    const delay = (hidden ? hideDelay : showDelay) * 1000;
    if (delay === 0) {
      setIsHidden(hidden);
      return;
    }

    const timer = setTimeout(() => setIsHidden(hidden), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [hidden, hideDelay, showDelay]);

  return (
    <div
      style={{
        ...style,
        display: isHidden ? 'none' : style?.display ?? 'block',
      }}
    >
      {children}
    </div>
  );
};

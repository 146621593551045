import { MotionValue, useTransform } from 'framer-motion';
import React, { FC, useState } from 'react';
import { mq } from '~/config/mq';
import { useMedia } from '~/hooks';
import { Camera } from '../Camera';
import { LayeredHero } from '../LayeredHero';

type SceneProps = {
  percentScrolled: MotionValue;
};

export const Scene: FC<SceneProps> = ({ percentScrolled }) => {
  // we want the hero to fill width on vertical devices, but reduce on landscape/desktop.
  // pages/index.tsx also uses isLandscape for negative top/bottom margins around hero
  const [isLandscape, setIsLandscape] = useState(false);
  useMedia(mq.landscape, setIsLandscape);
  const cameraStart = 1.3; // be sure to check top/bottom clipping on mobile, desk
  const cameraOffset = 3.5; // same here w/ bottom

  const cameraY = useTransform(
    // @ts-ignore
    percentScrolled,
    [0, 1],
    [cameraStart, cameraStart - cameraOffset],
  );

  return (
    <>
      <Camera makeDefault cameraY={cameraY} />
      <LayeredHero
        isLandscape={isLandscape}
        percentageScrolled={percentScrolled}
      />
    </>
  );
};

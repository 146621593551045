import { AnimatePresence } from 'framer-motion';
import { PageProps } from 'gatsby';
import 'intersection-observer'; // polyfill
import React, {
  CSSProperties,
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { isMobile as isMobileDevice } from 'react-device-detect';
import styled from 'styled-components';
import { AboutTheInitiative } from '~/components/AboutTheInitiative';
import { Blackbird } from '~/components/Blackbird';
import { Space } from '~/components/common/Space';
import { ContentHider } from '~/components/ContentHider';
import { Feed } from '~/components/Feed';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { Hero3D } from '~/components/Hero';
import { HowToHelp } from '~/components/HowToHelp/HowToHelp';
import { HowToHelpMobile } from '~/components/HowToHelp/HowToHelpMobile';
import { KnowTheFacts } from '~/components/KnowTheFacts';
import { Layout } from '~/components/Layout';
import { Leaf } from '~/components/Leaf';
import { Menu } from '~/components/Menu';
import { PageHeading } from '~/components/PageHeading';
import { Preloader } from '~/components/Preloader';
import { getPreloadBeat } from '~/components/Preloader/styles';
import LerpScroll from '~/components/smooth/LerpScroll';
import { VisibleInView } from '~/components/VisibleInView';
import { isDev } from '~/config';
import { data, slugs } from '~/config/data-types';
import { mq } from '~/config/mq';
import { TabbingContext } from '~/context/TabbingContext';
import { useMedia } from '~/hooks';
import { useListComplete } from '~/hooks/useListComplete';
import { plxBreak } from '~/util/plx';

const HeaderMemo = memo(Header);
const PageHeadingMemo = memo(PageHeading);
const Hero3DMemo = memo(Hero3D);
const FeedMemo = memo(Feed);
const AboutTheInitiativeMemo = memo(AboutTheInitiative);
const KnowTheFactsMemo = memo(KnowTheFacts);
const HowToHelpMobileMemo = memo(HowToHelpMobile);
const HowToHelpMemo = memo(HowToHelp);
const FooterMemo = memo(Footer);
const LeafMemo = memo(Leaf);
const BlackbirdMemo = memo(Blackbird);
const PreloaderMemo = memo(Preloader);

// -- wrapper styles --

const Grid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;
`;

// -- Component --

const Page: FC<PageProps> = () => {
  const preloadOnDev = false;
  const showMenu = true;
  const showHeading = true;
  const showHero = true;
  const showFeed = true;
  const showAbout = true;
  const showFacts = true;
  const showHelp = true;
  const showFooter = true;
  const showLeaves = true;
  const showBirds = true;
  const lerpScroll = true;
  const showFPS = false;

  const preloadKeys = [] as string[];
  if (showHero) {
    preloadKeys.push('hero');
  }
  if (showFeed) {
    preloadKeys.push('feed');
  }

  // this query also covers landscape on phone
  const [isMobile, setIsMobile] = useState(false);
  const { isMediaQueryOnline } = useMedia(mq.mobile, setIsMobile);

  const { hasTabbed } = useContext(TabbingContext);

  // ...but we also use this special query for scrolling elements
  // to default to the desktop scroll breakpoints in landscape.
  const [isLandscape, setIsLandscape] = useState(false);
  useMedia(mq.landscape, setIsLandscape);

  const [isTablet, setIsTablet] = useState(false);
  useMedia(mq.tablet, setIsTablet);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    const open = !isMenuOpen;
    setMenuOpen(open);
    window.scrollTo({ top: 0 });
    if (!open) {
      document.body.style.overflow = 'visible';
    }
  }, [isMenuOpen]);

  // falls back from landscape -> desktop
  const bp: plxBreak = isMobile && !isLandscape ? 'm' : 'd';

  const { completeForKey, isListComplete } = useListComplete(
    preloadKeys,
    getPreloadBeat(3),
  );
  const isPreloaded = isListComplete || (isDev && !preloadOnDev);

  const handleHeroLoaded = useCallback(() => {
    completeForKey('hero');
  }, [completeForKey]);

  const handleFeedLoaded = useCallback(() => {
    completeForKey('feed');
  }, [completeForKey]);

  const heroStyle = useMemo(
    () =>
      ({
        pointerEvents: 'none', // or hero blocks menu hovers/clicks
        width: '60vw',
        // currently the hero is locked to 1:1 so these margins snug to prev/next sections.
        // Scene.tsx also uses isLandscape so hero fills width on vertical devices
        marginTop: isLandscape ? (isMobile ? '-150px' : '-270px') : '0px',
        marginBottom: isLandscape ? '-20vw' : '-10vw',
      } as CSSProperties),
    [isLandscape, isMobile],
  );

  // `useMemo` would actually be less efficient here since something is updated on every render anyway
  const content = (
    <>
      <ContentHider hidden={isMenuOpen} showDelay={0}>
        <Grid
          style={{
            background: 'radial-gradient(#D9F5FA, #B8E1E9 60%, #B8E1E9)',
          }}
        >
          <div>
            {showMenu && isPreloaded && (
              <VisibleInView
                id="menu"
                options={{ rootMargin: '100px 0px 0px 0px' }}
              >
                <HeaderMemo
                  bp={bp}
                  isMobile={isMobile}
                  toggleMenu={toggleMenu}
                  menuOpen={isMenuOpen}
                />
              </VisibleInView>
            )}
            {!(isMobile && isLandscape) && <Space size={77} />}
          </div>

          <div>
            {showHeading && isPreloaded && (
              <VisibleInView id="heading">
                <PageHeadingMemo bp={bp} />
              </VisibleInView>
            )}
          </div>

          {showHero /* always allow for preload */ && (
            <VisibleInView
              id="hero"
              disabled={!isPreloaded}
              options={{ rootMargin: '0% 0% 100% 0%' }}
            >
              <div style={heroStyle}>
                <Hero3DMemo onLoad={handleHeroLoaded} hold={!isPreloaded} />
              </div>
            </VisibleInView>
          )}
        </Grid>
        <Grid>
          {showFeed /* always allow for preload */ && (
            <VisibleInView id="feed" disabled={!isPreloaded}>
              <FeedMemo onLoad={handleFeedLoaded} />
            </VisibleInView>
          )}

          <Space size={100} />

          <span id={slugs[0]} /* do not move */ />
          {showAbout && isPreloaded && isMediaQueryOnline && (
            <VisibleInView id="about">
              <AboutTheInitiativeMemo
                bp={bp}
                isMobile={isMobile}
                isLandscape={isLandscape}
              />
            </VisibleInView>
          )}

          <Space size={isMobile ? (isLandscape ? 200 : 200) : 300} />
          <span id={slugs[1]} /* do not move */ />
          {showFacts && isPreloaded && (
            <VisibleInView id="facts">
              <KnowTheFactsMemo
                bp={bp}
                isMobileLandscape={isMobile && isLandscape}
              />
            </VisibleInView>
          )}

          <Space size={isMobile ? 150 : 50} />
          <span id={slugs[2]} /* do not move */ />

          {showHelp && isMobile && isMediaQueryOnline && (
            <HowToHelpMobileMemo />
          )}
          {showHelp && !isMobile && isPreloaded && isMediaQueryOnline && (
            <VisibleInView id="help">
              <HowToHelpMemo bp={bp} isTablet={isTablet} />
            </VisibleInView>
          )}

          {showFooter && isPreloaded && (
            <VisibleInView id="footer">
              <FooterMemo />
            </VisibleInView>
          )}
        </Grid>
        {/* top of last section */}
        {showLeaves && isPreloaded && (
          <>
            <LeafMemo seed={0} size={isTablet ? 80 : 50} bottomOffset={1200} />
            <LeafMemo seed={1} size={isTablet ? 70 : 45} bottomOffset={1200} />
            <LeafMemo seed={2} size={isTablet ? 60 : 40} bottomOffset={1200} />
            <LeafMemo seed={3} size={isTablet ? 50 : 30} bottomOffset={1200} />
            <LeafMemo seed={4} size={isTablet ? 40 : 25} bottomOffset={1200} />
          </>
        )}

        {/* over footer only */}
        {showBirds && isPreloaded && (
          <>
            <BlackbirdMemo
              seed={3}
              size={isTablet ? 70 : 60}
              bottomOffset={isMobile ? (isLandscape ? 400 : 650) : 550}
            />
            <BlackbirdMemo
              seed={4}
              size={isTablet ? 50 : 50}
              bottomOffset={isMobile ? (isLandscape ? 400 : 650) : 550}
            />
          </>
        )}
      </ContentHider>

      {showMenu && isPreloaded && isMobile && (
        <AnimatePresence>
          {isMenuOpen && (
            <Menu
              links={data.mainLinks}
              toggleMenu={toggleMenu}
              isOpen={isMenuOpen}
            />
          )}
        </AnimatePresence>
      )}
    </>
  );

  const doLerpScroll =
    lerpScroll &&
    // Delayed scrolling doesn't feel right on touch devices (see also Plx.tsx)
    !isMobileDevice &&
    // Once tab key is pressed, lerp is disabled so keyboard-based users are able to
    // jump naturally on tab-focus. There's a side effect: the Hero reloads, since we
    // need to redraw the main render tree to switch this. Thus the state is kept stable
    // for the rest of the session after user tabbed, to minimize flashing.
    !hasTabbed;
  return (
    <Layout showFPS={showFPS && isPreloaded} isMenuOpen={isMenuOpen}>
      {doLerpScroll ? <LerpScroll ease={0.1}>{content}</LerpScroll> : content}
      <PreloaderMemo loading={!isPreloaded} />
    </Layout>
  );
};

export default Page;

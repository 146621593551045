import React, { FC } from 'react';
import styled from 'styled-components';
import { WayToHelp as WayToHelpType } from '~/config/data-types';
import { desktop } from '~/config/mq';
import LinkBox from '../../images/link-box.svg';
import { Fade, LinkPlus } from '../common';

const Container = styled.div`
  pointer-events: all;
  width: 100%;
  margin-top: 160px;
`;

const Heading = styled.h2`
  transition: all 0.2s ease-in-out;

  color: ${({ theme }) => theme.color.green};
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.h1Mobile};
  line-height: 1.25;
  margin: 0;
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.font.body};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.25;
`;

const Links = styled.ul`
  list-style: none;
  text-align: left;
  margin: 2em auto;
  height: 30vh; // generally fills the screen without falling off
  overflow: auto;
`;

const LinkItem = styled.li`
  border-top: 1px solid #c2c2c2;
  padding-top: 1em;
  padding-left: 1em;
  a {
    width: 100%;
  }

  :hover {
    opacity: 0.8;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
  }
`;

const Description = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    width: 85%;
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.sm};
    line-height: ${({ theme }) => theme.font.size.md};
    font-weight: normal;
  }
  svg {
    width: 20px;
    height: 20px;
    margin: auto 0;
    margin-right: 6%;
    justify-self: left;
  }
`;

const LinksContainer = styled.div`
  width: 75%;
  ${desktop`width: 44%`};
  margin: 0 auto;
`;

const Padded = styled.div`
  padding: 0 40px;
`;

type WayToHelpProps = WayToHelpType;

export const WayToHelp: FC<WayToHelpProps> = ({ subhead, text, links }) => {
  return (
    <Container>
      <Fade>
        <LinksContainer>
          <Padded>
            <Heading>{subhead}</Heading>
            <Text>{text}</Text>
          </Padded>

          <Links>
            {links.map((l, i) => (
              <LinkItem key={i}>
                <LinkPlus to={l.url}>
                  {l.title}
                  <Description>
                    <p>{l.description}</p>
                    <LinkBox />
                  </Description>
                </LinkPlus>
              </LinkItem>
            ))}
          </Links>
        </LinksContainer>
      </Fade>
    </Container>
  );
};

import { navigate } from 'gatsby';
import { isSSG } from '~/config';

/**
 * Use this instead of `navigate` to avoid build problems with SSG
 *
 * @param pathname e.g. '/pagename'
 * @param replace pass true for redirect
 */
export const navigateSafe = (pathname: string, replace = false) => {
  if (!isSSG) {
    if (!replace && pathname.indexOf('http' || 'mailto') == 0) {
      window.open(pathname, '_blank');
    } else if (!replace && pathname.indexOf('mailto') == 0) {
      window.open(pathname, 'noreferrer noopener');
    } else {
      navigate(pathname, { replace });
    }
  }
};

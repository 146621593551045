import { isValidElement, ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

export const randomHex = () => {
  const hex = ((Math.random() * 0xffffff) << 0).toString(16);
  return hex.padEnd(6, hex.slice(-1));
};

/**
 * For cases where all you have to generate a JSX key is a piece of
 * body text or something else that might contain non-alpha characters
 *
 * @param obj A string, ReactNode, or unique chunk of data
 */
export const makeKey = (obj: string | ReactNode | unknown) =>
  (isValidElement(obj as ReactNode)
    ? // @ts-ignore type error showed up here around react 18
      renderToStaticMarkup(obj)
    : JSON.stringify(obj)
  )
    .replace(/[^a-zA-Z]/g, '')
    .slice(0, 50) ??
  // fallback: this is not desired, it will cause the element to always
  // rerender -- try to be sure you're passing in something usable!
  randomHex();

export const isExternalUrl = (to: string) => /^https?:\/\//.test(to);

export const prependSlash = (to: string) =>
  !to || to.charAt(0) === '/' ? to : `/${to}`;

/** converts an object into a querystring */
export const encodeQueryString = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

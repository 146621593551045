import { Plane, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { MotionValue, useTransform } from 'framer-motion';
import { motion } from 'framer-motion-3d';
import React, { FC, useRef } from 'react';
import bearOne from '~/images/hero/bear/bear-0.png';
import bearTwo from '~/images/hero/bear/bear-1.png';
import birdOne from '~/images/hero/bird/bird-0.png';
import birdTwo from '~/images/hero/bird/bird-1.png';
import birdThree from '~/images/hero/bird/bird-2.png';
import cloudOne from '~/images/hero/clouds/cloud-0.png';
import cloudTwo from '~/images/hero/clouds/cloud-1.png';
import cloudThree from '~/images/hero/clouds/cloud-2.png';
import cloudFour from '~/images/hero/clouds/cloud-3.png';
import deerOne from '~/images/hero/deer/deer-0.png';
import deerTwo from '~/images/hero/deer/deer-1.png';
import heartFrame from '~/images/hero/heart-frame-01.png';
import midGround from '~/images/hero/mid-ground-01.png';
import skyBackground from '~/images/hero/sky-background-01.png';
import squirrelOne from '~/images/hero/squirrel/squirrel-0.png';
import squirrelTwo from '~/images/hero/squirrel/squirrel-1.png';
import sun from '~/images/hero/sun.png';

type LayeredHeroProps = {
  isLandscape?: boolean;
  percentageScrolled: MotionValue;
};

export const LayeredHero: FC<LayeredHeroProps> = ({
  isLandscape,
  percentageScrolled,
}) => {
  const frameNumber = useRef(0);
  const squirrelSeq = useRef(0);
  const birdSeq = useRef(0);
  const bearSeq = useRef(0);
  const deerSeq = useRef(0);

  const squirrelRef = useRef(null);
  const bearRef = useRef(null);
  const birdRef = useRef(null);
  const deerRef = useRef(null);

  const animateCloudOne = useTransform(percentageScrolled, [0, 0.5], [7, 1]);
  const animateCloudTwo = useTransform(percentageScrolled, [0, 0.5], [-8, -1]);
  const animateCloudThree = useTransform(percentageScrolled, [0, 0.5], [-4, 1]);
  const animateCloudFour = useTransform(percentageScrolled, [0, 0.5], [4, 2]);
  const animateSun = useTransform(percentageScrolled, [-0.5, 0.5], [-5, 0.5]);

  const [
    HeartTexture,
    MidTexture,
    SkyBackground,
    Sun,
    CloudOne,
    CloudTwo,
    CloudThree,
    CloudFour,
    BirdOne,
    BirdTwo,
    BirdThree,
    DeerOne,
    DeerTwo,
    BearOne,
    BearTwo,
    SquirrelOne,
    SquirrelTwo,
  ] = useTexture([
    heartFrame,
    midGround,
    skyBackground,
    sun,
    cloudOne,
    cloudTwo,
    cloudThree,
    cloudFour,
    birdOne,
    birdTwo,
    birdThree,
    deerOne,
    deerTwo,
    bearOne,
    bearTwo,
    squirrelOne,
    squirrelTwo,
  ]);

  const BirdTextures = [BirdOne, BirdTwo, BirdOne, BirdThree];
  const DeerTextures = [DeerOne, DeerTwo];
  const BearTextures = [BearOne, BearTwo];
  const SquirrelTextures = [SquirrelOne, SquirrelTwo];

  const updateMaterial = (ref, currentIndex, textureArray) => {
    ref.current.material.map = textureArray[currentIndex];
  };

  useFrame(() => {
    frameNumber.current += 1;

    /**
     * Squirrel sequence
     *
     */
    if (frameNumber.current % 120 === 0) {
      squirrelSeq.current = (squirrelSeq.current + 1) % SquirrelTextures.length;
      updateMaterial(squirrelRef, squirrelSeq.current, SquirrelTextures);
    }

    /**
     * Deer sequence
     *
     */
    if (frameNumber.current % 200 === 0) {
      deerSeq.current = (deerSeq.current + 1) % DeerTextures.length;
      updateMaterial(deerRef, deerSeq.current, DeerTextures);
    }

    /**
     * Bear sequence
     *
     */
    if (frameNumber.current % 170 === 0) {
      bearSeq.current = (bearSeq.current + 1) % BearTextures.length;
      updateMaterial(bearRef, bearSeq.current, BearTextures);
    }

    /**
     * Bird sequence
     *
     */
    if (frameNumber.current % 100 === 0) {
      birdSeq.current = (birdSeq.current + 1) % BirdTextures.length;
      updateMaterial(birdRef, birdSeq.current, BirdTextures);
    }
  });

  return (
    <>
      <group scale={isLandscape ? 1 : 1.4}>
        <motion.mesh
          position-x={animateCloudOne}
          position-y={2}
          position-z={-6}
          scale={1}
        >
          <planeBufferGeometry args={[5, 2]} />
          {/* @ts-ignore */}
          <meshBasicMaterial map={CloudOne} attach="material" transparent />
        </motion.mesh>
        <motion.mesh
          position-x={animateCloudTwo}
          position-z={-6}
          position-y={2}
          scale={1}
        >
          <planeBufferGeometry args={[3, 1.75]} />
          {/* @ts-ignore */}
          <meshBasicMaterial map={CloudTwo} attach="material" transparent />
        </motion.mesh>
        <motion.mesh
          position-x={animateCloudThree}
          position-z={-6}
          position-y={3.5}
          scale={1}
        >
          <planeBufferGeometry args={[2, 1]} />
          {/* @ts-ignore */}
          <meshBasicMaterial map={CloudThree} attach="material" transparent />
        </motion.mesh>
        <motion.mesh
          position-x={animateCloudFour}
          position-z={-6}
          position-y={4}
          scale={0.5}
        >
          <planeBufferGeometry args={[1.75, 1.25]} />
          {/* @ts-ignore */}
          <meshBasicMaterial map={CloudFour} attach="material" transparent />
        </motion.mesh>
        <PlaneLayer
          imageTexture={SkyBackground}
          size={[2, 1.5]}
          position={[0, 0.6, -5]}
          scale={[2, 2, 2]}
        />
        <motion.mesh position-z={-5} position-y={animateSun} scale={1}>
          <planeBufferGeometry args={[1, 1]} />
          {/* @ts-ignore */}
          <meshBasicMaterial map={Sun} attach="material" transparent />
        </motion.mesh>
        <PlaneLayer
          imageTexture={MidTexture}
          size={[3, 1.5]}
          position={[0.4, -1, -2.5]}
          scale={[1.6, 1.6, 1.6]}
        />
        <mesh ref={bearRef} position={[1.0, -1.15, 0]}>
          <planeBufferGeometry args={[2.25, 1.65]} />
          {/* @ts-ignore */}
          <meshBasicMaterial
            map={BearTextures[0]}
            attach="material"
            transparent
          />
        </mesh>
        <mesh ref={deerRef}>
          <planeBufferGeometry args={[5, 5]} />
          {/* @ts-ignore */}
          <meshBasicMaterial
            map={DeerTextures[0]}
            attach="material"
            transparent
          />
        </mesh>
        <PlaneLayer
          imageTexture={HeartTexture}
          size={[5, 5]}
          position={[0, 0, 0]}
        />
        <mesh ref={birdRef} position={[0.5, 1.7, 0]}>
          <planeBufferGeometry args={[0.35, 0.35]} />
          {/* @ts-ignore */}
          <meshBasicMaterial
            map={BirdTextures[0]}
            attach="material"
            transparent
          />
        </mesh>
        <mesh ref={squirrelRef} position={[-1.15, -1.75, 0]}>
          <planeBufferGeometry args={[2.5, 1.5]} />
          {/* @ts-ignore */}
          <meshBasicMaterial
            map={SquirrelTextures[0]}
            attach="material"
            transparent
          />
        </mesh>
      </group>
      {/* <Clouds3DFiber /> */}
    </>
  );
};

const PlaneLayer = ({ imageTexture, position, size, scale = [1, 1, 1] }) => {
  return (
    // @ts-ignore
    <Plane args={size} position={position} scale={scale}>
      <meshBasicMaterial map={imageTexture} attach="material" transparent />
    </Plane>
  );
};

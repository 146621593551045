import React, { FC } from 'react';
import styled from 'styled-components';
import { Plx } from '~/components/Plx';
import { data } from '~/config/data-types';
import { desktop, tablet } from '~/config/mq';
import { navigateSafe } from '~/util';
import { PlxBreakProps, responsivePlx } from '~/util/plx';
import { BeatingButton } from './BeatingButton';
import { Space } from './common/Space';
import { Heading } from './common/styled-components/Typography';

const makeFactPlx = (index: number) => [
  {
    start: `#fact-${index}`,
    startOffset: '0vh',
    duration: '60vh',
    easing: [0.19, 1.19, 0.74, 0.98],
    properties: [
      {
        property: 'translateX',
        startValue: 500,
        endValue: 0,
      },
      /* {
        property: 'opacity',
        startValue: 0,
        endValue: 1,
      }, */
    ],
  },
  {
    start: `#fact-${index}`,
    startOffset: '95vh',
    duration: '60vh',
    easing: 'easeOutQuad',
    properties: [
      {
        property: 'translateX',
        startValue: 0,
        endValue: -500,
      },
      /* {
        property: 'opacity',
        startValue: 1,
        endValue: 0,
      }, */
    ],
  },
];

// -- styles --

const Container = styled.div<{ index: number }>`
  /* height: 65vh; // amount of scroll per item. can affect overlap */
  margin-bottom: 250px;
  overflow: hidden;
`;

const FactsPlx = styled(Plx)`
  top: calc(50vh - 145px); // subtraction is half estimated height
  ${desktop`
    top: calc(50vh - 175px); // subtraction is half estimated height
  `}
  width: 100%;
  padding: 0 20px;
  text-align: center;
  /* opacity: 0; // clamp start opacity so you don't see it peek out before it starts */
`;

const BigText = styled.div<{ isMobileLandscape: boolean }>`
  color: ${({ theme }) => theme.color.green};
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.xl};
  ${(props) => (props.isMobileLandscape ? 'margin: -20px 0;' : '')}
  ${tablet`
    font-size: ${({ theme }) => theme.font.size.xxl};
  `}
  ${desktop`
    font-size: ${({ theme }) => theme.font.size.giant};
  `}
`;

const Text = styled.div<{ green?: boolean }>`
  color: ${(props) =>
    props.green ? props.theme.color.green : props.theme.color.black};
  font-size: ${(props) => props.theme.font.size.md};
  font-weight: ${(props) => (props.green ? 700 : 400)};
`;

export const KnowTheFacts: FC<
  PlxBreakProps & { isMobileLandscape: boolean }
> = ({ bp, isMobileLandscape }) => {
  return (
    <>
      {data.facts.map((fact, i) => {
        return (
          <Container key={fact.bigText} id={`fact-${i}`} index={i}>
            <FactsPlx parallaxData={responsivePlx(bp, makeFactPlx(i))}>
              <Heading>Know the facts</Heading>
              <BigText isMobileLandscape={isMobileLandscape}>
                {fact.bigText}
              </BigText>
              <Text>{fact.text}</Text>
              <Text green>{fact.greenText}</Text>
              {fact.url && (
                <>
                  <Space size="1em" />
                  <BeatingButton
                    fullOfHeart
                    label="our insta has all the facts"
                    onClick={() => navigateSafe(fact.url)}
                  />
                </>
              )}
            </FactsPlx>
          </Container>
        );
      })}
    </>
  );
};

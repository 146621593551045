import { motion } from 'framer-motion';
import React, { FC, MouseEvent } from 'react';
import styled, { keyframes } from 'styled-components';
import { Burger } from '~/components/Burger';
import { MainLink, partnerLink } from '~/config/data-types';
import { easing } from '~/config/easing';
import { useKeyPress } from '~/hooks';
import deer from '~/images/deer.png';
import LeafSVG from '~/images/leaf.svg';
import { navigateSafe } from '~/util';
import { BeatingButton } from './BeatingButton';
import { Fade } from './common';
import { handleAnchorClick } from './HeaderLink';

const PADDING = '5vw';

const MenuContainer = styled(motion.div)`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.color.offWhite};
  overflow: hidden;
`;

const MenuContent = styled.div`
  position: absolute;
  z-index: 3;
  right: ${PADDING};
  top: 22vh;
  text-align: right;
`;

const MenuLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.color.offWhite};
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: 700;
  margin: 1.5rem 0;

  &:visited {
    color: ${({ theme }) => theme.color.offWhite};
  }
`;

const Tagline = styled.h3`
  margin: 4rem 0 0.7rem;

  text-align: center;
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.h3Mobile};
  line-height: 1;
`;

const rideInOnTheBreeze = keyframes`
  from {
    transform: translate3d(-100%, 30%, 0) scale(0.25) rotate(90deg);
  }
  to {
    transform: none;
  }
`;

const slideIn = keyframes`
  from {
    transform: translate3d(-100%, 10%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const ambleOnOver = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(2deg);
  }
`;

const DeerWrap = styled.div`
  position: absolute;
  bottom: 48px;
  left: -5vw;

  width: 30vw;

  animation: ${slideIn} 1.8s 1.4s ${easing.out_sine} both;
`;

const Deer = styled.img`
  display: block;
  width: 100%;
  height: auto;

  animation: ${ambleOnOver} 0.125s 1.1s ${easing.in_out_expo} both alternate;
  animation-iteration-count: 12;
`;

const Leaf = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation: ${rideInOnTheBreeze} 1.8s 0.5s ${easing.out_quart} both;
`;

const LeafSvg = styled(LeafSVG)`
  position: absolute;
  left: -25%;
  top: -60%;

  display: block;
  width: 220%;
  height: 220%;

  transform: rotate(-30deg);
`;

const Signoff = styled.div`
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.color.white};
  text-align: right;
  position: absolute;
  right: ${PADDING};
  bottom: ${PADDING};
`;

type MenuProps = {
  links: MainLink[];
  toggleMenu: () => void;
  isOpen: boolean;
};

export const Menu: FC<MenuProps> = ({ links, toggleMenu, isOpen }) => {
  useKeyPress('Escape', toggleMenu);

  const closeOnAnchorClick = (e: MouseEvent<HTMLAnchorElement>) => {
    toggleMenu();

    // brief delay while main content is restored (it's hidden from keyboard navigation while menu is open)
    e.preventDefault();
    const hash = e.currentTarget.hash;
    setTimeout(() => handleAnchorClick(hash), 200);
  };

  return (
    <MenuContainer
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.2 },
      }}
      onAnimationComplete={
        // a little smoother to hide scrollbar after the initial fade, but during menu build-in
        isOpen ? () => (document.body.style.overflow = 'hidden') : undefined
      }
    >
      <MenuContent>
        <Fade
          seconds={1.4}
          delaySeconds={0.2}
          stagger={0.05}
          enterEasing={easing.out_quart}
        >
          {links.map((item) => (
            <MenuLink
              key={`${item.label}--mobile`}
              href={`/#${item.slug}`}
              onClick={closeOnAnchorClick}
            >
              {item.label}
            </MenuLink>
          ))}
          <Tagline>Have a heart. Go green.</Tagline>
          <div>
            <BeatingButton
              label="y'all want to partner?"
              invertColors
              onClick={() => navigateSafe(partnerLink)}
            />
          </div>
        </Fade>
      </MenuContent>
      <Leaf>
        <LeafSvg />
      </Leaf>
      <DeerWrap>
        <Deer src={deer} />
      </DeerWrap>
      <Fade delaySeconds={1}>
        <Signoff>© 2021 y’all, LLC. Do shit that helps people.</Signoff>
      </Fade>
      <div
        style={{
          position: 'absolute',
          top: 30,
          right: 15,
        }}
      >
        <Burger open onClick={toggleMenu} />
      </div>
    </MenuContainer>
  );
};

import styled from 'styled-components';
import { desktop, tablet } from '~/config/mq';

export const Heading = styled.h2`
  transition: all 0.2s ease-in-out;
  text-align: center;
  color: black;
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.h1Mobile};
  line-height: 1;
  margin: 0;

  ${tablet`
    font-size: ${({ theme }) => theme.font.size.h1Tablet};
    // don't change line-height please -- Initiative layouts
  `}

  ${desktop`
    font-size: ${({ theme }) => theme.font.size.h1};
    // don't change line-height please -- Initiative layouts
  `}
`;

export const SubHeading = styled.h3`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: ${({ theme }) => theme.font.size.h2Mobile};
  text-align: center;

  ${desktop`
    font-size: ${({ theme }) => theme.font.size.h2};
    line-height: 2.25;
  `}
`;

export const P = styled.p`
  font-size: ${({ theme }) => theme.font.size.sm};
  ${desktop`
    font-size: ${({ theme }) => theme.font.size.md};
  `}
`;

import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Plx } from '~/components/Plx';
import { data } from '~/config/data-types';
import { desktop, tablet } from '~/config/mq';
import { plxBreak, PlxBreakProps, responsivePlx } from '~/util/plx';
import { easing } from '../../config/easing';
import { Fade } from '../common';
import { Heading } from '../common/styled-components/Typography';
import { IsVisibleContext } from '../VisibleInView';
import { HowToHelpFan } from './HowToHelpFan';
import { WayToHelp } from './WayToHelp';

const rotateOnSelect = true;

// in this component 'm' means tablet
const fanPlxData = [
  {
    start: '#help-marker',
    duration: '40vh',
    startOffset: '50vh',
    easing: 'easeInOutQuint',
    properties: [
      {
        property: 'scale',
        startValue: 1,
        endValue: 0.65,
      },
      {
        property: 'translateY',
        startValue: 20,
        endValue: 0,
      },
    ],
  },
];

// -- styles --

const Container = styled.div`
  z-index: 1;
  pointer-events: none;
  position: relative;
  width: 100vw;
  height: auto;
  text-align: center;
`;

const HelpHeading = styled(Heading)`
  margin-bottom: 1em;
  ${tablet`
    margin-bottom: 0.25em;
  `}
  ${desktop`
    margin-bottom: 0;
  `}
`;

const FanScaleContainer = styled(Plx)`
  transform-origin: top;
`;

const FanContainer = styled.div<{ selectedIndex: number; totalCards: number }>`
  transform: rotate(
    ${(props) =>
      (props.selectedIndex - (props.totalCards - 1) / 2) * -1 * 17}deg
  );
  transform-origin: center 360%;
  transition: all 0.3s ${easing.out_sine};
`;

const WayToHelpFade = styled.div`
  height: auto;
  margin-top: -180px;
  ${tablet`
    top: 360px;
  `}
`;

type HowToHelpProps = PlxBreakProps & {
  isTablet: boolean;
};

export const HowToHelp: FC<HowToHelpProps> = ({ bp, isTablet }) => {
  const { isVisible } = useContext(IsVisibleContext);
  const ways = data.waysToHelp;
  const [selectedIndex, setSelectedIndex] = useState(2);

  // reset selection when scrolled out of view, because it looks weird to have the fan unfurl from the side
  useEffect(() => {
    if (!isVisible) {
      setSelectedIndex(2);
    }
  }, [isVisible]);

  const handleCardClick = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  // rigging 'm' to mean tablet for this component, which is desktop-only
  const _bp: plxBreak = isTablet ? 'm' : 'd';

  return (
    <Container>
      <div id="help-marker" />

      <div>
        <HelpHeading>So, how can you help?</HelpHeading>

        {/*controls the rotation and selected state*/}
        <FanScaleContainer
          animateWhenNotInViewport // needed for reshow on reload, since fan plx's use this setting
          parallaxData={responsivePlx(_bp, fanPlxData)}
        >
          <FanContainer
            totalCards={ways.length}
            selectedIndex={rotateOnSelect ? selectedIndex : 2}
          >
            {/*controls the fan out animation*/}
            <HowToHelpFan
              /* originally we had a parallax wrapping this component that triggered interactive on start, but it was failing on page reload */
              isInteractive
              selectedIndex={selectedIndex}
              onCardClick={handleCardClick}
            />
          </FanContainer>
        </FanScaleContainer>

        <Fade>
          <WayToHelpFade>
            <WayToHelp
              {...ways[selectedIndex]}
              key={ways[selectedIndex].heading}
            />
          </WayToHelpFade>
        </Fade>
      </div>
    </Container>
  );
};

import styled, { keyframes } from 'styled-components';
import { easing } from '~/config/easing';
import { desktop, tablet } from '~/config/mq';
import BearSvg from '~/images/bear.svg';
import CloudsSvg from '~/images/clouds.svg';
import DeerSvg from '~/images/deer.svg';
import HeartSvg from '~/images/heart.svg';
import SquirrelSvg from '~/images/squirrel.svg';
import TreeSvg from '~/images/tree.svg';

export const Wrapper = styled.div`
  overflow: hidden;

  position: fixed;
  left: 0;
  top: 0;

  z-index: 10;

  width: 100%;
  height: 100vh;

  transition: opacity 0.5s linear;
  opacity: 0;

  &.loading {
    opacity: 1;
  }

  // doing this last minute since the sizing consts are hard to convert to responsive - MG
  @media (orientation: portrait) {
    transform: scale(3);
  }
  @media (orientation: landscape) {
    transform: scale(2);
  }
  ${tablet`
    transform: scale(1.8);
  `}
  ${desktop`
    transform: scale(1);
  `}
`;

export const Background = styled.span`
  position: absolute;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;

  background-color: ${(props) => props.theme.color.blue};
`;

const BADGE_SIZE = 20; // vw

const heartBeat = keyframes`
  0% {    transform: scale(0.85)  }
  15% {   transform: scale(1)    }
  30% {   transform: scale(0.88) }
  50% {   transform: scale(0.92) }
  100% {  transform: scale(0.85)  }
`;

export const Badge = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;

  width: ${BADGE_SIZE}vw;
  height: ${BADGE_SIZE}vw;
  margin: -${BADGE_SIZE / 2}vw 0 0 -${BADGE_SIZE / 2}vw;

  animation: ${heartBeat} 2.4s 0s infinite;

  ${Wrapper}.loading & {
    animation-duration: 1.2s;
  }
`;

export const Heart = styled(HeartSvg)`
  position: absolute;
  left: -${200 - BADGE_SIZE / 2}vw;
  top: -${200 - BADGE_SIZE / 2}vw;

  display: block;
  width: 400vw;
  height: 400vw;

  fill: ${(props) => props.theme.color.offWhite};

  transition: transform 0.6s ${easing.in_quart};

  ${Wrapper}.loading & {
    transform: ${`scale(${BADGE_SIZE / 400})`};
  }
`;

const ICON_SIZE = 6.5; // vw

export const Icons = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;

  width: ${ICON_SIZE}vw;
  height: ${ICON_SIZE}vw;
  margin: -${ICON_SIZE / 2}vw 0 0 -${ICON_SIZE / 2}vw;
`;

const iconCycle = keyframes`
  0% { opacity: 0; transform: scale(0.8); }
  15%, 85% { opacity: 1; transform: scale(0.9); }
  100% { opacity: 0; transform: none; }
`;

const SvgIconStyles = `
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  // Offset to center within heart
  margin: 1.25vw 0 0 -0.25vw;
`;

const duration = 1;
const baseDelay = 0.5;
const beatTime = 1.2;
export const getPreloadBeat = (count = 5) => beatTime * count;

export const Deer = styled(DeerSvg)`
  ${SvgIconStyles}

  animation: ${iconCycle} ${duration}s ${baseDelay + beatTime * 0}s both;
`;

export const Tree = styled(TreeSvg)`
  ${SvgIconStyles}

  animation: ${iconCycle} ${duration}s ${baseDelay + beatTime * 1}s both;
`;

export const Bear = styled(BearSvg)`
  ${SvgIconStyles}

  animation: ${iconCycle} ${duration}s ${baseDelay + beatTime * 2}s both;
`;

export const Clouds = styled(CloudsSvg)`
  ${SvgIconStyles}

  animation: ${iconCycle} ${duration}s ${baseDelay + beatTime * 3}s both;
`;

export const Squirrel = styled(SquirrelSvg)`
  ${SvgIconStyles}

  animation: ${iconCycle} ${duration}s ${baseDelay + beatTime * 4}s both;
`;

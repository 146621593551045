import { fetch } from 'cross-fetch';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { desktop, tablet } from '~/config/mq';
import { navigateSafe } from '~/util';
import { BeatingButton } from './BeatingButton';
import Card from './Card';
import { Heading, P, SubHeading } from './common/styled-components/Typography';

type FeedItem = {
  caption: string;
  id: string;
  media_type: 'IMAGE';
  media_url: string;
  permalink: string;
  timestamp: string;
};

type FeedResponse = {
  data: FeedItem[];
  paging: {
    cursors: {
      before?: string;
      after?: string;
    };
  };
};

const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  margin: 2rem auto;
  z-index: 0;
`;

const FeedHeading = styled(Heading)`
  line-height: 2.25;
`;

const InstaPostsContainer = styled.div`
  grid-template-columns: auto auto;
  display: grid;
  gap: 0.25rem;
  justify-content: center;
  position: relative;
  margin: 1rem 0;
  ${tablet`
    gap: 1rem;
    `}
  ${desktop`
  margin: 1.5rem 0;
    display: flex;
    flex-wrap: wrap;
  `}
`;

const FeedSubHeading = styled(SubHeading)`
  color: ${({ theme }) => theme.color.blue};
  line-height: 1;
  margin-bottom: 0;
`;

const FeedP = styled(P)`
  font-size: ${({ theme }) => theme.font.size.md};
  line-height: 1.75;
  margin: 0.5rem auto;
  padding: 0 1.5em;
  text-align: center;
  ${tablet`
    max-width: 65ch;
  `}
  ${desktop`
    max-width: 85ch;
  `}
`;

const FeedButton = styled(BeatingButton)`
  margin: 1rem 0;
  :hover {
    background-color: ${({ theme }) => theme.color.green};
  }
`;

/* const LeafSwirlContainer = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`; */

type FeedProps = {
  onLoad?: () => void;
};

export const Feed: FC<FeedProps> = ({ onLoad }) => {
  // boolean + ref is used to avoid re-render from storing an array in state
  const [hasData, setHasData] = useState(false);
  const data = useRef<FeedItem[]>();

  useEffect(
    () => {
      const fetchFeed = async () => {
        let tries = 0;
        while (++tries <= 3 && !data.current) {
          const response = await fetch('/api/feed');
          try {
            if (response?.ok) {
              const result: FeedResponse = await response.json();
              data.current = result.data;
              setHasData(true);
              onLoad?.();
              break;
            }
          } catch (e) {
            console.error('unable to fetch feed', e);
          }
        }

        // avoid blocking page render if feed failed to load - component is designed to look ok without cards
        if (!data.current) {
          onLoad?.();
        }
      };

      fetchFeed();
    },
    // once only!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FeedContainer>
      {/* Disabling now that we have many swirling foreground leaves
      <LeafSwirlContainer>
        <LeafSwirl />
      </LeafSwirlContainer> */}
      <FeedHeading>Let's grow together</FeedHeading>
      <InstaPostsContainer>
        {hasData &&
          data.current!.slice(0, 4).map((item, idx) => {
            const caption = item.caption;
            return <Card key={idx} img={item.media_url} caption={caption} />;
          })}
      </InstaPostsContainer>
      <FeedSubHeading>Nurture your knowledge</FeedSubHeading>
      <FeedP>
        We're putting the <em>friendly</em> back in{' '}
        <em>environmentally friendly</em>. Follow our initiative on instagram to
        learn how to be more green and play a part in keeping our planet blue.
      </FeedP>
      <FeedP>
        <FeedButton
          label="@areyallgreen"
          fullOfHeart
          onClick={() =>
            navigateSafe('https://www.instagram.com/areyallgreen/')
          }
        />
      </FeedP>
    </FeedContainer>
  );
};

import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isDesktop } from 'react-device-detect';
import styled from 'styled-components';
import { TabbingContext } from '~/context/TabbingContext';
import { useRaf, useResize } from '~/hooks';
import LeafSvg from '~/images/leaf.svg';
import { clamp } from '~/util/math';

type LeafProps = {
  seed?: number;
  size: number;
  bottomOffset?: number;
};

export const Leaf: FC<LeafProps> = ({ seed = 0, size, bottomOffset = 0 }) => {
  const { hasTabbed } = useContext(TabbingContext);
  const [isActive, setActive] = useState(false);
  const { viewportWidth, viewportHeight, scrollHeight } = useResize();
  const ref = useRef<HTMLDivElement>(null);
  const data = useRef({
    x: 0,
    y: 0,
    xOffset: 0,
    yOffset: 0,
    xVel: 2,
    xShift: 0,
    c: 0,
    xLock: false,
    scrollY: 0,
  });

  // switched vertical follow from mouse to scroll, to avoid lurches - it's not all that diffrent though
  useEffect(() => {
    const handleScrollY = (e) => {
      data.current.scrollY = e.detail.scrollPositionY;
    };
    const eventName = isDesktop && !hasTabbed ? 'lerp-scroll' : 'window-scroll';

    window.addEventListener(eventName, handleScrollY, false);
    return () => window.removeEventListener(eventName, handleScrollY, false);
  }, [hasTabbed]);

  const update = useCallback(() => {
    if (!viewportWidth || !scrollHeight || !ref.current) {
      return;
    }
    const hFloat = 2; // sine wave multiplier
    const vFloat = 200; // sine wave multiplier
    const yDamp = 0.01; // scroll follow damping
    const d = { ...data.current };
    const vw = viewportWidth;
    const edgeTol = vw * 0.15;
    const y = clamp(
      data.current.scrollY + viewportHeight / 2,
      0,
      scrollHeight - bottomOffset,
    );

    // initial setup (early return above ensures we have window size)
    if (d.x === 0 && d.y === 0) {
      d.x = (seed * 500) % vw;
      d.y = y + seed * 25;
      d.xVel =
        Math.max(1, d.xVel - seed) * (seed % 2 ? 1 : -1) * ((700 - vw) * 0.002);
      d.c = seed * 20;
    }

    // vertical position is a damped mouse follow plus sine wave
    d.c++; // running counter
    d.yOffset = Math.sin((d.c * Math.PI) / 180) * vFloat + seed * 20;
    d.y += (y - d.y) * yDamp;

    // horizontal position is an incrementer that reverses direction randomly within bounds
    // combined with a sine wave to provide back-and-forth drifting effect
    d.xShift = Math.sin((d.c * Math.PI) / 180) * hFloat;
    d.x += d.xShift * d.xVel;
    d.x = Math.max(Math.min(d.x, vw), 0);

    // detect left and right edges when moving toward them, reverse direction and lock change
    if (
      !d.xLock &&
      ((d.xVel < 0 && d.x < edgeTol) || (d.xVel > 0 && vw - d.x < edgeTol))
    ) {
      d.xLock = true;
      d.xVel *= -1;
    }
    // once locked, randomize occasional reversal after having moved away from edge enough
    else if (
      d.xLock &&
      ((d.xVel > 0 && d.x > vw / 3) || (d.xVel < 0 && d.x < vw / 3)) &&
      Math.random() < 0.005
    ) {
      d.xLock = false;
      d.xVel *= -1;
    }

    // angle
    const angle = Math.round(Math.abs(vw / 2 - d.x / 1.5) * 2);

    // apply
    ref.current.style.top = `${d.y + d.yOffset}px`;
    ref.current.style.left = `${d.x}px`;
    ref.current.style.transform = `rotateZ(${angle}deg) rotateX(${
      d.x + (seed + 1) * 2
    }deg)`;

    Object.assign(data.current, d);
  }, [bottomOffset, scrollHeight, seed, viewportHeight, viewportWidth]);

  useRaf(true, update, 2);

  // staggers the lotties so they're not synced
  useEffect(() => {
    const delay = setTimeout(() => setActive(true), seed * 1000);
    return () => clearTimeout(delay);
  }, [seed]);

  return (
    <Container isActive={isActive} scrollHeight={scrollHeight}>
      <div
        id="leaf"
        ref={ref}
        style={{
          position: 'absolute',
          /* tall, spinny container */
          height: size * 3,
          transformOrigin: 'top left',
        }}
      >
        <LeafSvg
          style={{
            width: size,
            height: size,
            bottom: 0,
          }}
        />
      </div>
    </Container>
  );
};

const Container = styled.div<{ scrollHeight: number; isActive: boolean }>`
  pointer-events: none;
  position: absolute;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  height: ${(props) => props.scrollHeight}px;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.2s linear;
`;
